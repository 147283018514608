import { Grid, Checkbox, FormControlLabel, Typography, Paper } from '@mui/material';

const amenitiesList = [
  'Parking',
  'Security System',
  'Air Conditioning',
  'Heating',
  'Elevator',
  'Storage Space',
  'Wheelchair Access',
  'Internet',
  'Water',
  'Electricity'
];

const featuresList = [
  'High Ceilings',
  'Natural Light',
  'Soundproof',
  'Climate Control',
  'Loading Dock',
  '24/7 Access',
  'Ground Floor',
  'Separate Entrance',
  'CCTV'
];

function AmenitiesFeatures({ formData, onChange }) {
  const handleAmenityChange = (amenity) => {
    const newAmenities = formData.amenities.includes(amenity)
      ? formData.amenities.filter(a => a !== amenity)
      : [...formData.amenities, amenity];
    onChange('amenities', newAmenities);
  };

  const handleFeatureChange = (feature) => {
    const newFeatures = formData.features.includes(feature)
      ? formData.features.filter(f => f !== feature)
      : [...formData.features, feature];
    onChange('features', newFeatures);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <Paper elevation={2} sx={{ p: 2 }}>
          <Typography variant="h6" gutterBottom>
            Amenities
          </Typography>
          <Grid container>
            {amenitiesList.map((amenity) => (
              <Grid item xs={12} sm={6} key={amenity}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formData.amenities.includes(amenity)}
                      onChange={() => handleAmenityChange(amenity)}
                    />
                  }
                  label={amenity}
                />
              </Grid>
            ))}
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12} md={6}>
        <Paper elevation={2} sx={{ p: 2 }}>
          <Typography variant="h6" gutterBottom>
            Features
          </Typography>
          <Grid container>
            {featuresList.map((feature) => (
              <Grid item xs={12} sm={6} key={feature}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formData.features.includes(feature)}
                      onChange={() => handleFeatureChange(feature)}
                    />
                  }
                  label={feature}
                />
              </Grid>
            ))}
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default AmenitiesFeatures;
