import { Box, Container, Typography, Grid, Paper, Button, IconButton, Chip } from '@mui/material';
import { motion, AnimatePresence } from 'framer-motion';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PersonIcon from '@mui/icons-material/Person';
import HomeIcon from '@mui/icons-material/Home';
import BusinessIcon from '@mui/icons-material/Business';
import EuroIcon from '@mui/icons-material/Euro';
import SecurityIcon from '@mui/icons-material/Security';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import StarIcon from '@mui/icons-material/Star';
import HandshakeIcon from '@mui/icons-material/Handshake';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import SpeedIcon from '@mui/icons-material/Speed';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';

function UserBenefits() {
  const navigate = useNavigate();
  const [hoveredType, setHoveredType] = useState(null);

  const userTypes = [
    {
      title: "For Renters",
      subtitle: "Find Your Perfect Storage Space",
      icon: <PersonIcon sx={{ fontSize: 60 }} />,
      color: "#2196F3",
      bgGradient: "linear-gradient(135deg, rgba(33, 150, 243, 0.1) 0%, rgba(33, 150, 243, 0.05) 100%)",
      benefits: [
        {
          icon: <EuroIcon />,
          title: "Cost-Effective Storage",
          description: "Save up to 50% compared to traditional storage solutions",
          highlight: "50% Savings"
        },
        {
          icon: <SecurityIcon />,
          title: "Verified Spaces",
          description: "Every space is verified and insured for your peace of mind",
          highlight: "100% Verified"
        },
        {
          icon: <AccessTimeIcon />,
          title: "Flexible Terms",
          description: "Rent monthly with no long-term commitments required",
          highlight: "Monthly Rentals"
        },
        {
          icon: <SupportAgentIcon />,
          title: "24/7 Support",
          description: "Round-the-clock customer service for all your needs",
          highlight: "Always Available"
        }
      ],
      cta: {
        text: "Find Storage Space",
        path: "/property/browse"
      }
    },
    {
      title: "For Hosts",
      subtitle: "Monetize Your Extra Space",
      icon: <HomeIcon sx={{ fontSize: 60 }} />,
      color: "#4CAF50",
      bgGradient: "linear-gradient(135deg, rgba(76, 175, 80, 0.1) 0%, rgba(76, 175, 80, 0.05) 100%)",
      benefits: [
        {
          icon: <AccountBalanceWalletIcon />,
          title: "Maximize Income",
          description: "Earn up to €500/month from your unused space",
          highlight: "€500/month"
        },
        {
          icon: <VerifiedUserIcon />,
          title: "Secure Platform",
          description: "Protected payments and verified renters only",
          highlight: "Secure Payments"
        },
        {
          icon: <SpeedIcon />,
          title: "Smart Management",
          description: "Efficient tools to manage bookings and payments",
          highlight: "Easy Tools"
        },
        {
          icon: <HandshakeIcon />,
          title: "Flexible Control",
          description: "Set your own rules, prices, and availability",
          highlight: "Full Control"
        }
      ],
      cta: {
        text: "List Your Space",
        path: "/property/new"
      }
    },
    {
      title: "For Businesses",
      subtitle: "Scalable Storage Solutions",
      icon: <BusinessIcon sx={{ fontSize: 60 }} />,
      color: "#FF9800",
      bgGradient: "linear-gradient(135deg, rgba(255, 152, 0, 0.1) 0%, rgba(255, 152, 0, 0.05) 100%)",
      benefits: [
        {
          icon: <EuroIcon />,
          title: "Cost Optimization",
          description: "Reduce overhead with flexible storage solutions",
          highlight: "Reduce Costs"
        },
        {
          icon: <StarIcon />,
          title: "Premium Features",
          description: "Advanced security and management capabilities",
          highlight: "Enterprise Grade"
        },
        {
          icon: <AccessTimeIcon />,
          title: "Scalable Solutions",
          description: "Grow or shrink your storage as needed",
          highlight: "Full Flexibility"
        },
        {
          icon: <SecurityIcon />,
          title: "Dedicated Support",
          description: "Priority support and account management",
          highlight: "VIP Service"
        }
      ],
      cta: {
        text: "Business Solutions",
        path: "/business"
      }
    }
  ];

  return (
    <Box sx={{ py: 8, bgcolor: 'background.default' }}>
      <Container maxWidth="lg">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          viewport={{ once: true }}
        >
          <Typography
            variant="h3"
            component="h2"
            sx={{
              textAlign: 'center',
              mb: 2,
              fontWeight: 600,
            }}
          >
            Perfect Storage Solutions for Everyone
          </Typography>
          <Typography
            variant="h6"
            component="p"
            sx={{
              textAlign: 'center',
              mb: 8,
              color: 'text.secondary',
              maxWidth: '800px',
              mx: 'auto'
            }}
          >
            Whether you're looking to store, share, or optimize your space, 
            we have the perfect solution tailored to your needs
          </Typography>
        </motion.div>

        <Grid container spacing={4}>
          {userTypes.map((type, index) => (
            <Grid item xs={12} md={4} key={index}>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.2 }}
                viewport={{ once: true }}
                onHoverStart={() => setHoveredType(index)}
                onHoverEnd={() => setHoveredType(null)}
              >
                <Paper
                  elevation={hoveredType === index ? 8 : 2}
                  sx={{
                    p: 4,
                    height: '100%',
                    background: type.bgGradient,
                    transition: 'all 0.3s ease-in-out',
                    transform: hoveredType === index ? 'translateY(-8px)' : 'none',
                    position: 'relative',
                    overflow: 'hidden',
                  }}
                >
                  <motion.div
                    animate={{
                      scale: hoveredType === index ? 1.1 : 1,
                      rotate: hoveredType === index ? 5 : 0
                    }}
                    transition={{ duration: 0.3 }}
                  >
                    <Box
                      sx={{
                        color: type.color,
                        mb: 3,
                        display: 'inline-block',
                      }}
                    >
                      {type.icon}
                    </Box>
                  </motion.div>

                  <Typography
                    variant="h4"
                    sx={{
                      mb: 1,
                      fontWeight: 600,
                      color: type.color
                    }}
                  >
                    {type.title}
                  </Typography>

                  <Typography
                    variant="subtitle1"
                    sx={{
                      mb: 4,
                      color: 'text.secondary'
                    }}
                  >
                    {type.subtitle}
                  </Typography>

                  <Box sx={{ mb: 4 }}>
                    {type.benefits.map((benefit, idx) => (
                      <Box
                        key={idx}
                        sx={{
                          display: 'flex',
                          alignItems: 'flex-start',
                          mb: 3,
                          gap: 2
                        }}
                      >
                        <Box sx={{ 
                          color: type.color,
                          minWidth: 40,
                          height: 40,
                          borderRadius: '50%',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          bgcolor: `${type.color}15`
                        }}>
                          {benefit.icon}
                        </Box>
                        <Box>
                          <Typography
                            variant="subtitle1"
                            sx={{ 
                              fontWeight: 600,
                              mb: 0.5,
                              display: 'flex',
                              alignItems: 'center',
                              gap: 1
                            }}
                          >
                            {benefit.title}
                            <Chip
                              label={benefit.highlight}
                              size="small"
                              sx={{
                                bgcolor: `${type.color}15`,
                                color: type.color,
                                fontWeight: 600
                              }}
                            />
                          </Typography>
                          <Typography
                            variant="body2"
                            sx={{ 
                              color: 'text.secondary',
                              lineHeight: 1.6
                            }}
                          >
                            {benefit.description}
                          </Typography>
                        </Box>
                      </Box>
                    ))}
                  </Box>

                  <Button
                    variant="contained"
                    fullWidth
                    sx={{
                      bgcolor: type.color,
                      '&:hover': {
                        bgcolor: type.color,
                        filter: 'brightness(0.9)'
                      }
                    }}
                    onClick={() => navigate(type.cta.path)}
                  >
                    {type.cta.text}
                  </Button>
                </Paper>
              </motion.div>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}

export default UserBenefits;