import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../config/firebase';
import {
  Container,
  Grid,
  Typography,
  Box,
  Paper,
  Divider,
  Chip,
  Stack,
  Button,
  useTheme,
  alpha,
  Dialog,
  DialogTitle,
  DialogContent
} from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import SquareFootIcon from '@mui/icons-material/SquareFoot';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import EuroIcon from '@mui/icons-material/Euro';
import SecurityIcon from '@mui/icons-material/Security';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PropertyGallery from '../components/property/PropertyGallery';
import RentalRequest from '../components/property/RentalRequest';
import { useAuth } from '../hooks/useAuth';
import { motion } from 'framer-motion';
import { logEvent } from '../utils/analytics';
import { addDoc, collection, updateDoc } from 'firebase/firestore';
import { createNotification } from '../utils/notificationUtils';
import { useNavigate } from 'react-router-dom';
import PaymentForm from '../components/payment/PaymentForm';

function PropertyDetails() {
  const { id } = useParams();
  const { user } = useAuth();
  const theme = useTheme();
  const [property, setProperty] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [rentalRequestOpen, setRentalRequestOpen] = useState(false);
  const [paymentDialogOpen, setPaymentDialogOpen] = useState(false);
  const [paymentProcessing, setPaymentProcessing] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPropertyDetails = async () => {
      try {
        const docRef = doc(db, 'listings', id);
        const docSnap = await getDoc(docRef);
        
        if (docSnap.exists()) {
          setProperty({ id: docSnap.id, ...docSnap.data() });
        } else {
          setError('Property not found');
        }
      } catch (error) {
        setError('Error fetching property details');
      } finally {
        setLoading(false);
      }
    };

    fetchPropertyDetails();
  }, [id]);

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <Typography>Loading...</Typography>
      </Box>
    );
  }

  if (error || !property) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  const FeatureCard = ({ icon, title, value }) => (
    <Paper
      elevation={0}
      sx={{
        p: 2,
        backgroundColor: alpha(theme.palette.primary.main, 0.05),
        borderRadius: 2,
        display: 'flex',
        alignItems: 'center',
        gap: 2
      }}
    >
      {icon}
      <Box>
        <Typography variant="body2" color="text.secondary">
          {title}
        </Typography>
        <Typography variant="h6">
          {value}
        </Typography>
      </Box>
    </Paper>
  );

  const handleViewContract = (contract) => {
    logEvent('Property', 'View Contract', contract.propertyTitle);
    // Rest of your existing code
  };

  const handlePaymentSuccess = async (paymentResponse) => {
    try {
      setPaymentProcessing(true);
      
      // Create rental request
      const requestData = {
        propertyId: property.id,
        propertyTitle: property.title,
        landlordId: property.userId,
        tenantId: user.uid,
        tenantEmail: user.email,
        tenantName: user.displayName,
        status: 'approved',
        startDate: new Date().toISOString(),
        rentalPeriod: property.minRentalPeriod || 1,
        monthlyRent: property.price,
        securityDeposit: property.securityDeposit,
        paymentId: paymentResponse.pspReference,
        paymentStatus: 'paid',
        createdAt: new Date().toISOString()
      };

      // Add the rental request to Firestore
      const requestRef = await addDoc(collection(db, 'rentalRequests'), requestData);

      // Update property status
      await updateDoc(doc(db, 'listings', property.id), {
        availabilityStatus: 'Rented',
        currentTenant: user.uid
      });

      // Create notification for landlord
      await createNotification(
        property.userId,
        'new_rental',
        `Your property "${property.title}" has been rented.`,
        {
          propertyId: property.id,
          requestId: requestRef.id
        }
      );

      setPaymentDialogOpen(false);
      navigate('/my-rental-applications');
    } catch (error) {
      console.error('Error processing rental:', error);
    } finally {
      setPaymentProcessing(false);
    }
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 8 }}>
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Grid container spacing={4}>
          {/* Header Section */}
          <Grid item xs={12}>
            <Box sx={{ mb: 2 }}>
              <Typography variant="h3" gutterBottom sx={{ fontWeight: 700 }}>
                {property.title}
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <LocationOnIcon color="primary" />
                <Typography variant="h6" color="text.secondary">
                  {`${property.street}, ${property.city}, ${property.state} ${property.zipCode}`}
                </Typography>
              </Box>
            </Box>
          </Grid>

          {/* Gallery Section */}
          <Grid item xs={12}>
            <PropertyGallery images={property.images} />
          </Grid>

          {/* Key Features Section */}
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={3}>
                <FeatureCard
                  icon={<EuroIcon color="primary" />}
                  title="Monthly Rent"
                  value={`€${property.price}`}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <FeatureCard
                  icon={<SquareFootIcon color="primary" />}
                  title="Size"
                  value={`${property.size} m²`}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <FeatureCard
                  icon={<SecurityIcon color="primary" />}
                  title="Security Deposit"
                  value={`€${property.securityDeposit}`}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <FeatureCard
                  icon={<AccessTimeIcon color="primary" />}
                  title="Min. Rental Period"
                  value={`${property.minRentalPeriod} months`}
                />
              </Grid>
            </Grid>
          </Grid>

          {/* Main Content Section */}
          <Grid item xs={12} md={8}>
            <Paper elevation={0} sx={{ p: 4, backgroundColor: alpha(theme.palette.background.paper, 0.7) }}>
              <Typography variant="h5" gutterBottom sx={{ fontWeight: 600 }}>
                About this property
              </Typography>
              <Typography paragraph sx={{ color: 'text.secondary', lineHeight: 1.8 }}>
                {property.description}
              </Typography>

              <Divider sx={{ my: 4 }} />

              <Typography variant="h5" gutterBottom sx={{ fontWeight: 600 }}>
                Features & Amenities
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 600 }}>
                    Features
                  </Typography>
                  <Stack direction="row" spacing={1} flexWrap="wrap" gap={1}>
                    {property.features.map((feature) => (
                      <Chip
                        key={feature}
                        label={feature}
                        sx={{
                          backgroundColor: alpha(theme.palette.primary.main, 0.1),
                          color: theme.palette.primary.main,
                          fontWeight: 500
                        }}
                      />
                    ))}
                  </Stack>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 600 }}>
                    Amenities
                  </Typography>
                  <Stack direction="row" spacing={1} flexWrap="wrap" gap={1}>
                    {property.amenities.map((amenity) => (
                      <Chip
                        key={amenity}
                        label={amenity}
                        variant="outlined"
                        sx={{ borderColor: theme.palette.primary.main, color: theme.palette.primary.main }}
                      />
                    ))}
                  </Stack>
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          {/* Sidebar Section */}
          <Grid item xs={12} md={4}>
            <Paper
              elevation={0}
              sx={{
                p: 4,
                backgroundColor: alpha(theme.palette.background.paper, 0.7),
                position: 'sticky',
                top: 24
              }}
            >
              <Typography variant="h4" color="primary" gutterBottom sx={{ fontWeight: 700 }}>
                €{property.price}
                <Typography component="span" variant="body1" color="text.secondary">
                  /month
                </Typography>
              </Typography>

              <Box sx={{ mt: 3 }}>
                <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 600 }}>
                  Availability Status
                </Typography>
                <Chip
                  label={property.availabilityStatus}
                  color={property.availabilityStatus === 'Available Now' ? 'success' : 'primary'}
                  sx={{ fontWeight: 500 }}
                />
              </Box>

              {user && property && user.uid !== property.userId && property.availabilityStatus !== 'Rented' && (
                <Button
                  variant="contained"
                  fullWidth
                  size="large"
                  onClick={() => {
                    logEvent('Property', 'Initiate Payment', property.title);
                    setPaymentDialogOpen(true);
                  }}
                  sx={{
                    mt: 4,
                    py: 2,
                    fontWeight: 600,
                    fontSize: '1.1rem',
                    backgroundColor: theme.palette.success.main,
                    '&:hover': {
                      backgroundColor: theme.palette.success.dark,
                    }
                  }}
                >
                  Pay & Rent Now
                </Button>
              )}
            </Paper>
          </Grid>
        </Grid>
      </motion.div>

      {property && (
        <RentalRequest
          property={{
            id: property.id || '',
            title: property.title || '',
            price: property.price || 0,
            userId: property.userId || '',
            minRentalPeriod: property.minRentalPeriod || 1,
            securityDeposit: property.securityDeposit || 0
          }}
          open={rentalRequestOpen}
          onClose={() => setRentalRequestOpen(false)}
        />
      )}

      <Dialog 
        open={paymentDialogOpen} 
        onClose={() => !paymentProcessing && setPaymentDialogOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Complete Your Rental Payment</DialogTitle>
        <DialogContent>
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle1" gutterBottom>
              Property: {property.title}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Monthly Rent: €{property.price}<br />
              Security Deposit: €{property.securityDeposit}<br />
              Total Due Now: €{property.price + property.securityDeposit}
            </Typography>
          </Box>
          <PaymentForm
            amount={property.price + property.securityDeposit}
            propertyId={property.id}
            onSuccess={handlePaymentSuccess}
            onError={(error) => {
              console.error('Payment error:', error);
              setError('Payment failed. Please try again.');
            }}
          />
        </DialogContent>
      </Dialog>
    </Container>
  );
}

export default PropertyDetails;
