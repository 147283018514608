import { TextField, Grid } from '@mui/material';

function LocationDetails({ formData, onChange }) {
  const handleChange = (field) => (event) => {
    onChange(field, event.target.value);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TextField
          required
          fullWidth
          label="Street Address"
          value={formData.street}
          onChange={handleChange('street')}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          required
          fullWidth
          label="City"
          value={formData.city}
          onChange={handleChange('city')}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          required
          fullWidth
          label="State/Province"
          value={formData.state}
          onChange={handleChange('state')}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          required
          fullWidth
          label="Postal Code"
          value={formData.zipCode}
          onChange={handleChange('zipCode')}
        />
      </Grid>
    </Grid>
  );
}

export default LocationDetails;
