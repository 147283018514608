import { Box, Container, Grid, Typography, Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

function Footer() {
  return (
    <Box
      component="footer"
      sx={{
        py: 3,
        px: 2,
        mt: 'auto',
        backgroundColor: (theme) =>
          theme.palette.mode === 'light'
            ? theme.palette.grey[200]
            : theme.palette.grey[800],
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" color="text.primary" gutterBottom>
              About Vanbuur
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Sustainable storage sharing platform connecting property owners with those seeking storage solutions.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" color="text.primary" gutterBottom>
              Legal
            </Typography>
            <Box>
              <Link component={RouterLink} to="/privacy-policy" color="text.secondary" sx={{ display: 'block', mb: 1 }}>
                Privacy Policy
              </Link>
              <Link component={RouterLink} to="/terms-conditions" color="text.secondary" sx={{ display: 'block', mb: 1 }}>
                Terms & Conditions
              </Link>
              <Link component={RouterLink} to="/cookie-policy" color="text.secondary" sx={{ display: 'block', mb: 1 }}>
                Cookie Policy
              </Link>
              <Link component={RouterLink} to="/disclaimer" color="text.secondary" sx={{ display: 'block' }}>
                Disclaimer
              </Link>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" color="text.primary" gutterBottom>
              Support
            </Typography>
            <Box>
              <Link component={RouterLink} to="/contact" color="text.secondary" sx={{ display: 'block', mb: 1 }}>
                Contact Us
              </Link>
              <Link component={RouterLink} to="/faq" color="text.secondary" sx={{ display: 'block', mb: 1 }}>
                FAQ
              </Link>
              <Link component={RouterLink} to="/help-center" color="text.secondary" sx={{ display: 'block', mb: 1 }}>
                Help Center
              </Link>
              <Link href="mailto:support@vanbuur.com" color="text.secondary" sx={{ display: 'block' }}>
                support@vanbuur.com
              </Link>
            </Box>
          </Grid>
        </Grid>
        <Box sx={{ mt: 3, borderTop: 1, borderColor: 'divider', pt: 2 }}>
          <Typography variant="body2" color="text.secondary" align="center">
            © {new Date().getFullYear()} Vanbuur. All rights reserved.
          </Typography>
        </Box>
      </Container>
    </Box>
  );
}

export default Footer;
