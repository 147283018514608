import { Container, Typography, Box, Paper } from '@mui/material';

function PrivacyPolicy() {
  return (
    <Container maxWidth="md">
      <Box sx={{ my: 4 }}>
        <Typography variant="h3" component="h1" gutterBottom>
          Privacy Policy
        </Typography>
        
        <Paper sx={{ p: 4 }}>
          <Typography variant="h5" gutterBottom>
            Last Updated: {new Date().toLocaleDateString()}
          </Typography>

          <Typography variant="body1" paragraph>
            Vanbuur B.V., established in Amsterdam, Netherlands, is responsible for the processing of personal data as described in this privacy statement.
          </Typography>

          <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
            1. Contact Details
          </Typography>
          <Typography variant="body1" paragraph>
            Vanbuur B.V.<br />
            [Amsterdam Office Address]<br />
            Email: privacy@vanbuur.com<br />
            Chamber of Commerce (KVK) number: [Number]
          </Typography>

          <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
            2. Personal Data We Process
          </Typography>
          <Typography variant="body1" paragraph>
            We process the following personal data:
            <ul>
              <li>Name and address details</li>
              <li>Contact information (email, phone)</li>
              <li>Payment information</li>
              <li>Identity verification documents</li>
              <li>Property preferences and search history</li>
              <li>Communication records</li>
              <li>Technical data (IP address, device information)</li>
            </ul>
          </Typography>

          <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
            3. Legal Basis for Processing
          </Typography>
          <Typography variant="body1" paragraph>
            We process personal data based on:
            <ul>
              <li>Performance of our service agreement</li>
              <li>Legal obligations (including Dutch rental law)</li>
              <li>Legitimate interests</li>
              <li>Your explicit consent</li>
            </ul>
          </Typography>

          <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
            4. Data Retention
          </Typography>
          <Typography variant="body1" paragraph>
            We retain personal data no longer than necessary for the purposes for which it was collected. Rental records are kept for 7 years in compliance with Dutch tax law.
          </Typography>

          <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
            5. Data Sharing
          </Typography>
          <Typography variant="body1" paragraph>
            We share data with:
            <ul>
              <li>Property owners/tenants (as necessary for rental agreements)</li>
              <li>Payment processors within the EU</li>
              <li>Cloud service providers with EU data centers</li>
              <li>Government authorities when legally required</li>
            </ul>
          </Typography>

          <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
            6. Your Rights Under GDPR
          </Typography>
          <Typography variant="body1" paragraph>
            You have the right to:
            <ul>
              <li>Access your personal data</li>
              <li>Rectify inaccurate data</li>
              <li>Request erasure ('right to be forgotten')</li>
              <li>Restrict processing</li>
              <li>Data portability</li>
              <li>Object to processing</li>
              <li>Withdraw consent</li>
            </ul>
          </Typography>

          <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
            7. Dutch Data Protection Authority
          </Typography>
          <Typography variant="body1" paragraph>
            You have the right to file a complaint with the Dutch Data Protection Authority (Autoriteit Persoonsgegevens) if you believe we have not handled your data appropriately.
          </Typography>

          <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
            8. Updates to This Policy
          </Typography>
          <Typography variant="body1" paragraph>
            We may update this policy periodically. Significant changes will be communicated directly to users.
          </Typography>
        </Paper>
      </Box>
    </Container>
  );
}

export default PrivacyPolicy;
