import { useEffect } from 'react';
import { startTimeTracking, endTimeTracking } from '../utils/analytics';

export const usePageTracking = (pageName) => {
  useEffect(() => {
    startTimeTracking(pageName);
    
    return () => {
      endTimeTracking(pageName);
    };
  }, [pageName]);
};
