import { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Paper,
  List,
  ListItem,
  ListItemText,
  Chip,
  CircularProgress
} from '@mui/material';
import { collection, query, where, getDocs, orderBy } from 'firebase/firestore';
import { db } from '../../config/firebase';
import { useAuth } from '../../hooks/useAuth';

function MyRentalRequests() {
  const { user } = useAuth();
  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchRequests = async () => {
      try {
        const q = query(
          collection(db, 'rentalRequests'),
          where('tenantId', '==', user.uid),
          orderBy('createdAt', 'desc')
        );
        const querySnapshot = await getDocs(q);
        const requestsData = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          createdAt: doc.data().createdAt?.toDate()
        }));
        setRequests(requestsData);
      } catch (error) {
        console.error('Error fetching requests:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchRequests();
  }, [user.uid]);

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        My Rental Requests
      </Typography>
      {requests.length === 0 ? (
        <Typography color="text.secondary">
          You haven't made any rental requests yet.
        </Typography>
      ) : (
        <List>
          {requests.map((request) => (
            <Paper key={request.id} sx={{ mb: 2, p: 2 }}>
              <ListItem>
                <ListItemText
                  primary={request.propertyTitle}
                  secondary={
                    <>
                      <Typography component="span" variant="body2">
                        Start Date: {new Date(request.startDate).toLocaleDateString()}<br />
                        Rental Period: {request.rentalPeriod} months<br />
                        Monthly Rent: €{request.monthlyRent}<br />
                        Submitted: {request.createdAt?.toLocaleDateString()}
                      </Typography>
                    </>
                  }
                />
                <Box sx={{ ml: 2 }}>
                  <Chip
                    label={request.status}
                    color={
                      request.status === 'approved' ? 'success' :
                      request.status === 'rejected' ? 'error' :
                      'default'
                    }
                  />
                </Box>
              </ListItem>
            </Paper>
          ))}
        </List>
      )}
    </Box>
  );
}

export default MyRentalRequests;
