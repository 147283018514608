import { Container, Typography, Box, Paper, Grid, Card, CardContent, CardActionArea } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import GavelIcon from '@mui/icons-material/Gavel';
import SecurityIcon from '@mui/icons-material/Security';
import PaymentIcon from '@mui/icons-material/Payment';
import HomeIcon from '@mui/icons-material/Home';

function HelpCenter() {
  const navigate = useNavigate();

  const helpCategories = [
    {
      title: "Frequently Asked Questions",
      description: "Find quick answers to common questions about using Vanbuur",
      icon: <HelpOutlineIcon sx={{ fontSize: 40 }} />,
      path: "/faq"
    },
    {
      title: "Contact Support",
      description: "Get in touch with our support team for personalized help",
      icon: <ContactSupportIcon sx={{ fontSize: 40 }} />,
      path: "/contact"
    },
    {
      title: "Legal Information",
      description: "Access our terms, privacy policy, and other legal documents",
      icon: <GavelIcon sx={{ fontSize: 40 }} />,
      path: "/terms-conditions"
    },
    {
      title: "Safety & Security",
      description: "Learn about our safety measures and best practices",
      icon: <SecurityIcon sx={{ fontSize: 40 }} />,
      path: "/safety-guidelines"
    },
    {
      title: "Payment & Billing",
      description: "Understanding payments, refunds, and billing processes",
      icon: <PaymentIcon sx={{ fontSize: 40 }} />,
      path: "/payment-info"
    },
    {
      title: "Property Guidelines",
      description: "Guidelines for listing and renting properties",
      icon: <HomeIcon sx={{ fontSize: 40 }} />,
      path: "/property-guidelines"
    }
  ];

  return (
    <Container maxWidth="lg">
      <Box sx={{ my: 4 }}>
        <Typography variant="h3" component="h1" gutterBottom>
          Help Center
        </Typography>
        
        <Paper sx={{ p: 4, mb: 4 }}>
          <Typography variant="h5" gutterBottom>
            How can we help you today?
          </Typography>
          <Typography variant="body1" color="text.secondary" paragraph>
            Browse through our help resources or contact our support team for assistance.
          </Typography>
        </Paper>

        <Grid container spacing={3}>
          {helpCategories.map((category, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card sx={{ height: '100%' }}>
                <CardActionArea 
                  onClick={() => navigate(category.path)}
                  sx={{ height: '100%' }}
                >
                  <CardContent sx={{ textAlign: 'center', py: 4 }}>
                    <Box sx={{ mb: 2, color: 'primary.main' }}>
                      {category.icon}
                    </Box>
                    <Typography variant="h6" gutterBottom>
                      {category.title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {category.description}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>

        <Paper sx={{ p: 4, mt: 4 }}>
          <Typography variant="h5" gutterBottom>
            Need Direct Support?
          </Typography>
          <Typography variant="body1" paragraph>
            Our support team is available Monday through Friday, 9:00 AM - 6:00 PM CET.
            <br />
            Email: support@vanbuur.com
            <br />
            Phone: +31 (0) 20 123 4567
          </Typography>
        </Paper>
      </Box>
    </Container>
  );
}

export default HelpCenter;
