import { Card, CardContent, Typography, Box, Chip, Stack } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import SquareFootIcon from '@mui/icons-material/SquareFoot';
import { useNavigate } from 'react-router-dom';
import ImageWithFade from '../common/ImageWithFade';

function PropertyCard({ property }) {
  const navigate = useNavigate();

  return (
    <Card 
      sx={{
        maxWidth: 345,
        height: '100%',
        cursor: 'pointer',
        position: 'relative',
        overflow: 'visible',
      }}
      onClick={() => navigate(`/property/${property.id}`)}
    >
      <Box sx={{ position: 'relative', height: 200, borderRadius: '8px 8px 0 0', overflow: 'hidden' }}>
        <ImageWithFade
          src={property.images[0]}
          alt={property.title}
          sx={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            transition: 'transform 0.3s ease-in-out',
            '&:hover': {
              transform: 'scale(1.05)',
            },
          }}
        />
        <Chip
          label={`€${property.price}/month`}
          sx={{
            position: 'absolute',
            top: 16,
            right: 16,
            backgroundColor: 'white',
            color: 'primary.main',
            fontWeight: 600,
          }}
        />
      </Box>
      <CardContent>
        <Typography 
          gutterBottom 
          variant="h6" 
          component="div"
          sx={{ 
            fontSize: '1.1rem',
            fontWeight: 600,
            color: 'primary.main',
          }}
        >
          {property.title}
        </Typography>
        
        <Stack spacing={1} sx={{ mt: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', color: 'text.secondary' }}>
            <LocationOnIcon sx={{ mr: 1, fontSize: 20 }} />
            <Typography variant="body2">
              {property.location}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', color: 'text.secondary' }}>
            <SquareFootIcon sx={{ mr: 1, fontSize: 20 }} />
            <Typography variant="body2">
              {property.size} m²
            </Typography>
          </Box>
        </Stack>

        <Box sx={{ mt: 2, display: 'flex', gap: 1, flexWrap: 'wrap' }}>
          {property.features?.slice(0, 2).map((feature) => (
            <Chip
              key={feature}
              label={feature}
              size="small"
              sx={{ 
                backgroundColor: 'rgba(44, 62, 80, 0.1)',
                color: 'primary.main',
              }}
            />
          ))}
        </Box>
      </CardContent>
    </Card>
  );
}

export default PropertyCard;
