import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import { auth } from '../../config/firebase';
import { useAuth } from '../../hooks/useAuth';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Box,
  Avatar,
  Menu,
  MenuItem,
  IconButton,
  useScrollTrigger,
  Container,
  Slide,
  useTheme,
  alpha,
  useMediaQuery,
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Divider
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import NotificationSystem from '../notifications/NotificationSystem';
import HomeIcon from '@mui/icons-material/Home';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import MenuIcon from '@mui/icons-material/Menu';

function Navbar() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMobileMenuToggle = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      handleClose();
      setMobileMenuOpen(false);
      navigate('/');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const mobileMenuItems = [
    { text: 'Find Space', icon: <SearchIcon />, path: '/properties' },
    { text: 'List Space', icon: <AddIcon />, path: '/property/new' },
    ...(user ? [
      { text: 'Profile', icon: <Avatar sx={{ width: 24, height: 24 }} />, path: '/profile' },
      { text: 'My Properties', icon: <HomeIcon />, path: '/my-properties' },
      { text: 'Landlord Dashboard', icon: <HomeIcon />, path: '/landlord-dashboard' },
      { text: 'My Applications', icon: <SearchIcon />, path: '/my-applications' }
    ] : [
      { text: 'Sign In', path: '/signin' },
      { text: 'Sign Up', path: '/signup' }
    ])
  ];

  return (
    <AppBar 
      position="fixed" 
      elevation={0}
      sx={{
        backgroundColor: alpha(theme.palette.background.default, 0.95),
        backdropFilter: 'blur(8px)',
        borderBottom: `1px solid ${alpha(theme.palette.divider, 0.08)}`,
        height: '70px',
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
    >
      <Container maxWidth="lg">
        <Toolbar sx={{ py: 1, justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <HomeIcon 
              sx={{ 
                mr: 1, 
                color: theme.palette.primary.main,
                fontSize: 32
              }} 
            />
            <Typography 
              variant="h5" 
              component={RouterLink} 
              to="/" 
              sx={{ 
                textDecoration: 'none', 
                color: theme.palette.primary.main,
                fontWeight: 700,
                letterSpacing: '-0.5px'
              }}
            >
              Vanbuur
            </Typography>
          </Box>

          {isMobile ? (
            <IconButton
              aria-label="open drawer"
              edge="start"
              onClick={handleMobileMenuToggle}
              sx={{ 
                ml: 2,
                color: theme.palette.primary.main,
                '&:hover': {
                  backgroundColor: alpha(theme.palette.primary.main, 0.08)
                }
              }}
            >
              <MenuIcon sx={{ fontSize: 28 }} />
            </IconButton>
          ) : (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              {/* Desktop menu items */}
              <Button
                component={RouterLink}
                to="/properties"
                variant="contained"
                startIcon={<SearchIcon />}
                sx={{
                  height: 48,
                  px: 3
                }}
              >
                Find Space
              </Button>

              {user && (
                <Button
                  component={RouterLink}
                  to="/property/new"
                  variant="outlined"
                  startIcon={<AddIcon />}
                  sx={{
                    height: 48,
                    px: 3
                  }}
                >
                  List Space
                </Button>
              )}

              {user ? (
                <>
                  <NotificationSystem />
                  <IconButton onClick={handleMenu}>
                    <Avatar 
                      alt={user.displayName || 'User'} 
                      src={user.photoURL}
                      sx={{ width: 32, height: 32 }}
                    />
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    <MenuItem 
                      component={RouterLink} 
                      to="/profile" 
                      onClick={handleClose}
                    >
                      Profile
                    </MenuItem>
                    <MenuItem 
                      component={RouterLink} 
                      to="/my-properties" 
                      onClick={handleClose}
                    >
                      My Properties
                    </MenuItem>
                    <MenuItem 
                      component={RouterLink} 
                      to="/landlord-dashboard" 
                      onClick={handleClose}
                    >
                      Landlord Dashboard
                    </MenuItem>
                    <MenuItem 
                      component={RouterLink} 
                      to="/my-applications" 
                      onClick={handleClose}
                    >
                      My Applications
                    </MenuItem>
                    <Divider />
                    <MenuItem 
                      onClick={handleSignOut}
                      sx={{ color: 'error.main' }}
                    >
                      Sign Out
                    </MenuItem>
                  </Menu>
                </>
              ) : (
                <>
                  <Button
                    component={RouterLink}
                    to="/signin"
                    sx={{ height: 48 }}
                  >
                    Sign In
                  </Button>
                  <Button
                    component={RouterLink}
                    to="/signup"
                    variant="contained"
                    sx={{ height: 48, px: 3 }}
                  >
                    Sign Up
                  </Button>
                </>
              )}
            </Box>
          )}
        </Toolbar>
      </Container>

      {/* Mobile Drawer */}
      <Drawer
        anchor="right"
        open={mobileMenuOpen}
        onClose={() => setMobileMenuOpen(false)}
      >
        <Box
          sx={{ width: 250 }}
          role="presentation"
          onClick={() => setMobileMenuOpen(false)}
        >
          <List>
            {mobileMenuItems.map((item) => (
              <ListItem 
                button 
                key={item.text} 
                component={RouterLink} 
                to={item.path}
              >
                {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
                <ListItemText primary={item.text} />
              </ListItem>
            ))}
            {user && (
              <>
                <Divider />
                <ListItem button onClick={handleSignOut}>
                  <ListItemText primary="Sign Out" sx={{ color: 'error.main' }} />
                </ListItem>
              </>
            )}
          </List>
        </Box>
      </Drawer>
    </AppBar>
  );
}

export default Navbar;
