import {AdyenCheckout} from '@adyen/adyen-web';
import '@adyen/adyen-web/styles/adyen.css';

const ADYEN_PUBLIC_KEY = process.env.REACT_APP_ADYEN_PUBLIC_KEY;
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const initializeAdyenCheckout = async (amount) => {
  console.log('🔄 Initializing Adyen Checkout...', { amount });
  try {
    const paymentMethodsResponse = await fetchPaymentMethods();
    console.log('✅ Payment methods fetched:', paymentMethodsResponse);

    const configuration = {
      environment: 'test',
      clientKey: ADYEN_PUBLIC_KEY,
      locale: "nl-NL",
      countryCode: 'NL',
      paymentMethodsResponse,
      amount: {
        currency: 'EUR',
        value: Math.round(amount * 100)
      },
      analytics: {
        enabled: false
      },
      paymentMethodsConfiguration: {
        card: {
          hasHolderName: true,
          holderNameRequired: true,
          billingAddressRequired: false,
          enableStoreDetails: true
        },
        ideal: {
          showImage: true,
          issuer: ""
        },
        paypal: {
          amount: {
            currency: 'EUR',
            value: Math.round(amount * 100)
          },
          environment: 'test',
          countryCode: 'NL'
        }
      },
      onError: (error) => {
        console.error('❌ Adyen Create Error:', error);
      }
    };

    console.log('🔄 Creating Adyen Checkout with configuration:', configuration);
    const checkout = await AdyenCheckout(configuration);
    console.log('✅ Adyen Checkout instance created:', checkout);
    
    return checkout;
  } catch (error) {
    console.error('❌ Adyen initialization error:', error);
    throw error;
  }
};

export const fetchPaymentMethods = async () => {
  console.log('🔄 Fetching payment methods...');
  try {
    const response = await fetch(`${API_BASE_URL}/api/payment-methods`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    const data = await response.json();
    console.log('✅ Payment methods response:', data);
    return data;
  } catch (error) {
    console.error('❌ Error fetching payment methods:', error);
    throw error;
  }
};

export const makePayment = async (paymentMethod, amount, propertyId) => {
  const response = await fetch(`${API_BASE_URL}/api/payments`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      paymentMethod,
      amount,
      propertyId,
      currency: 'EUR',
    }),
  });
  return await response.json();
}; 