import { useState } from 'react';
import { Box } from '@mui/material';
import { keyframes } from '@mui/system';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

function ImageWithFade({ src, alt, sx, ...props }) {
  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <Box
      component="img"
      src={src}
      alt={alt}
      onLoad={() => setIsLoaded(true)}
      sx={{
        opacity: 0,
        animation: isLoaded ? `${fadeIn} 0.5s ease-in forwards` : 'none',
        ...sx
      }}
      {...props}
    />
  );
}

export default ImageWithFade;
