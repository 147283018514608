import { useState, useEffect } from 'react';
import {
  Container,
  Grid,
  Paper,
  Typography,
  Box,
  Button,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  Divider
} from '@mui/material';
import { collection, query, where, updateDoc, doc, onSnapshot } from 'firebase/firestore';
import { db } from '../config/firebase';
import { useAuth } from '../hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import { createNotification } from '../utils/notificationUtils';

function MyRentalApplications() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [applications, setApplications] = useState([]);
  const [contracts, setContracts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedContract, setSelectedContract] = useState(null);
  const [contractDialogOpen, setContractDialogOpen] = useState(false);

  useEffect(() => {
    const requestsQuery = query(
      collection(db, 'rentalRequests'),
      where('tenantId', '==', user.uid)
    );

    const contractsQuery = query(
      collection(db, 'rentalContracts'),
      where('tenantId', '==', user.uid)
    );

    const unsubscribeRequests = onSnapshot(requestsQuery, (snapshot) => {
      const requestsData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setApplications(requestsData);
    });

    const unsubscribeContracts = onSnapshot(contractsQuery, (snapshot) => {
      const contractsData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setContracts(contractsData);
      setLoading(false);
    });

    return () => {
      unsubscribeRequests();
      unsubscribeContracts();
    };
  }, [user.uid]);

  const handleViewContract = (contract) => {
    setSelectedContract(contract);
    setContractDialogOpen(true);
  };

  const handleSignContract = async () => {
    try {
      await updateDoc(doc(db, 'rentalContracts', selectedContract.id), {
        status: 'active',
        tenantSignedAt: new Date().toISOString()
      });

      // Update property status
      await updateDoc(doc(db, 'listings', selectedContract.propertyId), {
        availabilityStatus: 'Rented',
        currentTenant: user.uid
      });

      // Create notification for landlord
      await createNotification(
        selectedContract.landlordId,
        'contract_signed',
        `The tenant has signed the rental contract for ${selectedContract.propertyTitle}.`,
        {
          propertyId: selectedContract.propertyId,
          contractId: selectedContract.id
        }
      );

      setContractDialogOpen(false);
    } catch (error) {
      console.error('Error signing contract:', error);
    }
  };

  const getStatusChipColor = (status) => {
    switch (status) {
      case 'pending':
        return 'warning';
      case 'approved':
        return 'success';
      case 'rejected':
        return 'error';
      default:
        return 'default';
    }
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4" gutterBottom>
        My Rental Applications
      </Typography>

      <Grid container spacing={3}>
        {/* Active Contracts */}
        {contracts.length > 0 && (
          <Grid item xs={12}>
            <Typography variant="h5" gutterBottom>
              Active Contracts
            </Typography>
            {contracts.map((contract) => (
              <Paper key={contract.id} sx={{ p: 3, mb: 2 }}>
                <Typography variant="h6">{contract.propertyTitle}</Typography>
                <Box sx={{ mt: 2 }}>
                  <Typography>Start Date: {new Date(contract.startDate).toLocaleDateString()}</Typography>
                  <Typography>End Date: {new Date(contract.endDate).toLocaleDateString()}</Typography>
                  <Typography>Monthly Rent: €{contract.monthlyRent}</Typography>
                </Box>
                <Button
                  variant="outlined"
                  sx={{ mt: 2 }}
                  onClick={() => handleViewContract(contract)}
                >
                  View Contract Details
                </Button>
              </Paper>
            ))}
          </Grid>
        )}

        {/* Pending Applications */}
        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom>
            Applications
          </Typography>
          {applications.map((application) => (
            <Paper key={application.id} sx={{ p: 3, mb: 2 }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="h6">{application.propertyTitle}</Typography>
                <Chip
                  label={application.status}
                  color={getStatusChipColor(application.status)}
                />
              </Box>
              <Box sx={{ mt: 2 }}>
                <Typography>Submitted: {new Date(application.createdAt).toLocaleDateString()}</Typography>
                <Typography>Desired Start Date: {new Date(application.startDate).toLocaleDateString()}</Typography>
                <Typography>Rental Period: {application.rentalPeriod} months</Typography>
                <Typography>Monthly Rent: €{application.monthlyRent}</Typography>
              </Box>
              {application.status === 'approved' && application.contractId && (
                <Button
                  variant="contained"
                  sx={{ mt: 2 }}
                  onClick={() => handleViewContract(contracts.find(c => c.id === application.contractId))}
                >
                  View Contract
                </Button>
              )}
            </Paper>
          ))}
        </Grid>
      </Grid>

      {/* Contract Dialog */}
      <Dialog
        open={contractDialogOpen}
        onClose={() => setContractDialogOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Rental Contract</DialogTitle>
        <DialogContent>
          {selectedContract && (
            <Box sx={{ mt: 2 }}>
              <Typography variant="h6" gutterBottom>
                Contract Details
              </Typography>
              <Divider sx={{ mb: 2 }} />
              <Typography><strong>Property:</strong> {selectedContract.propertyTitle}</Typography>
              <Typography><strong>Start Date:</strong> {new Date(selectedContract.startDate).toLocaleDateString()}</Typography>
              <Typography><strong>End Date:</strong> {new Date(selectedContract.endDate).toLocaleDateString()}</Typography>
              <Typography><strong>Monthly Rent:</strong> €{selectedContract.monthlyRent}</Typography>
              <Typography><strong>Security Deposit:</strong> €{selectedContract.securityDeposit}</Typography>
              
              {selectedContract.specialTerms && (
                <>
                  <Typography variant="h6" sx={{ mt: 3, mb: 1 }}>
                    Special Terms and Conditions
                  </Typography>
                  <Typography>{selectedContract.specialTerms}</Typography>
                </>
              )}
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setContractDialogOpen(false)}>Close</Button>
          {selectedContract?.status === 'pending_tenant_signature' && (
            <Button onClick={handleSignContract} variant="contained" color="primary">
              Sign Contract
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default MyRentalApplications;
