import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { Box } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { AuthProvider } from './context/AuthContext';
import Navbar from './components/layout/Navbar';
import theme from './styles/theme';
import Footer from './components/layout/Footer';
import { useEffect } from 'react';
import { initGA, logPageView } from './utils/analytics';
// import '@adyen/adyen-web/dist/es/adyen.css';

// Pages
import Home from './pages/Home';
import PropertyListing from './pages/PropertyListing';
import SignIn from './pages/SignIn';
import SignUp from './pages/SignUp';
import Profile from './pages/Profile';
import PrivateRoute from './components/common/PrivateRoute';
import MyProperties from './pages/MyProperties';
import PropertyDetails from './pages/PropertyDetails';
import LandlordDashboard from './pages/LandlordDashboard';
import MyRentalApplications from './pages/MyRentalApplications';
import PrivacyPolicy from './pages/legal/PrivacyPolicy';
import TermsConditions from './pages/legal/TermsConditions';
import CookiePolicy from './pages/legal/CookiePolicy';
import Disclaimer from './pages/legal/Disclaimer';
import FAQ from './pages/help/FAQ';
import HelpCenter from './pages/help/HelpCenter';

function App() {
  const location = useLocation();

  useEffect(() => {
    initGA();
  }, []);

  useEffect(() => {
    logPageView(location.pathname);
  }, [location]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AuthProvider>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
          }}
        >
          <Navbar />
          <Box 
            component="main" 
            sx={{ 
              flex: 1,
              mt: '70px',
            }}
          >
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/properties" element={<PropertyListing mode="browse" />} />
              <Route 
                path="/property/new" 
                element={
                  <PrivateRoute>
                    <PropertyListing mode="create" />
                  </PrivateRoute>
                } 
              />
              <Route path="/signin" element={<SignIn />} />
              <Route path="/signup" element={<SignUp />} />
              <Route 
                path="/profile" 
                element={
                  <PrivateRoute>
                    <Profile />
                  </PrivateRoute>
                } 
              />
              <Route 
                path="/my-properties" 
                element={
                  <PrivateRoute>
                    <MyProperties />
                  </PrivateRoute>
                } 
              />
              <Route 
                path="/property/edit/:id" 
                element={
                  <PrivateRoute>
                    <PropertyListing mode="edit" />
                  </PrivateRoute>
                } 
              />
              <Route path="/property/:id" element={<PropertyDetails />} />
              <Route 
                path="/landlord-dashboard" 
                element={
                  <PrivateRoute>
                    <LandlordDashboard />
                  </PrivateRoute>
                } 
              />
              <Route 
                path="/my-applications" 
                element={
                  <PrivateRoute>
                    <MyRentalApplications />
                  </PrivateRoute>
                } 
              />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/terms-conditions" element={<TermsConditions />} />
              <Route path="/cookie-policy" element={<CookiePolicy />} />
              <Route path="/disclaimer" element={<Disclaimer />} />
              <Route path="/faq" element={<FAQ />} />
              <Route path="/help-center" element={<HelpCenter />} />
            </Routes>
          </Box>
          <Footer />
        </Box>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
