import { Container, Typography, Box, Paper } from '@mui/material';

function Disclaimer() {
  return (
    <Container maxWidth="md">
      <Box sx={{ my: 4 }}>
        <Typography variant="h3" component="h1" gutterBottom>
          Disclaimer
        </Typography>
        
        <Paper sx={{ p: 4 }}>
          <Typography variant="h5" gutterBottom>
            Last Updated: {new Date().toLocaleDateString()}
          </Typography>

          <Typography variant="body1" paragraph>
            The information provided on Vanbuur is for general informational purposes only. All information is provided in good faith, however, we make no representation or warranty of any kind, express or implied.
          </Typography>

          <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
            1. No Guarantees
          </Typography>
          <Typography variant="body1" paragraph>
            Vanbuur does not guarantee:
            <ul>
              <li>The accuracy or completeness of property listings</li>
              <li>The availability of properties</li>
              <li>The reliability of users</li>
              <li>The outcome of any rental agreement</li>
            </ul>
          </Typography>

          <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
            2. User Responsibility
          </Typography>
          <Typography variant="body1" paragraph>
            Users acknowledge that:
            <ul>
              <li>They are responsible for verifying all information</li>
              <li>They must conduct their own due diligence</li>
              <li>They should seek professional advice when necessary</li>
              <li>They use the platform at their own risk</li>
            </ul>
          </Typography>

          <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
            3. Third-Party Content
          </Typography>
          <Typography variant="body1" paragraph>
            Our platform may contain links to third-party websites or services. We are not responsible for:
            <ul>
              <li>The content of external websites</li>
              <li>Third-party services or products</li>
              <li>User-generated content</li>
              <li>External advertisements</li>
            </ul>
          </Typography>

          <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
            4. Limitation of Liability
          </Typography>
          <Typography variant="body1" paragraph>
            To the maximum extent permitted by law, we shall not be liable for:
            <ul>
              <li>Direct, indirect, or consequential losses</li>
              <li>Property damage or personal injury</li>
              <li>Financial losses or damages</li>
              <li>Loss of data or business interruption</li>
            </ul>
          </Typography>

          <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
            5. Professional Advice
          </Typography>
          <Typography variant="body1" paragraph>
            The content on Vanbuur does not constitute professional advice. Users should:
            <ul>
              <li>Consult legal professionals for rental agreements</li>
              <li>Seek financial advice for investment decisions</li>
              <li>Verify property conditions through inspections</li>
              <li>Review local laws and regulations</li>
            </ul>
          </Typography>

          <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
            6. Contact Information
          </Typography>
          <Typography variant="body1" paragraph>
            For questions about this disclaimer, please contact us at:
            <br />
            Email: legal@vanbuur.com
            <br />
            Address: [Your Company Address]
          </Typography>
        </Paper>
      </Box>
    </Container>
  );
}

export default Disclaimer;
