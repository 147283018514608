import { Container, Typography, Box, Paper } from '@mui/material';

function CookiePolicy() {
  return (
    <Container maxWidth="md">
      <Box sx={{ my: 4 }}>
        <Typography variant="h3" component="h1" gutterBottom>
          Cookie Policy
        </Typography>
        
        <Paper sx={{ p: 4 }}>
          <Typography variant="h5" gutterBottom>
            Last Updated: {new Date().toLocaleDateString()}
          </Typography>

          <Typography variant="body1" paragraph>
            This Cookie Policy explains how Vanbuur uses cookies and similar technologies to recognize you when you visit our platform.
          </Typography>

          <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
            1. What are Cookies?
          </Typography>
          <Typography variant="body1" paragraph>
            Cookies are small data files that are placed on your computer or mobile device when you visit a website. They are widely used to make websites work more efficiently and provide useful information to website owners.
          </Typography>

          <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
            2. How We Use Cookies
          </Typography>
          <Typography variant="body1" paragraph>
            We use cookies for the following purposes:
            <ul>
              <li>Authentication and security</li>
              <li>Preferences and functionality</li>
              <li>Analytics and performance</li>
              <li>Advertising and targeting</li>
              <li>Session management</li>
            </ul>
          </Typography>

          <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
            3. Types of Cookies We Use
          </Typography>
          <Typography variant="body1" paragraph>
            <ul>
              <li><strong>Essential Cookies:</strong> Required for basic platform functionality</li>
              <li><strong>Functional Cookies:</strong> Remember your preferences and settings</li>
              <li><strong>Analytics Cookies:</strong> Help us understand how you use our platform</li>
              <li><strong>Marketing Cookies:</strong> Used to deliver relevant advertisements</li>
            </ul>
          </Typography>

          <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
            4. Third-Party Cookies
          </Typography>
          <Typography variant="body1" paragraph>
            Some cookies are placed by third-party services that appear on our pages. We use third-party cookies for:
            <ul>
              <li>Analytics (Google Analytics)</li>
              <li>Payment processing</li>
              <li>Social media integration</li>
              <li>Security verification</li>
            </ul>
          </Typography>

          <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
            5. Managing Cookies
          </Typography>
          <Typography variant="body1" paragraph>
            Most web browsers allow you to control cookies through their settings preferences. However, limiting cookies may affect the functionality of our platform. You can manage your cookie preferences by:
            <ul>
              <li>Adjusting your browser settings</li>
              <li>Using our cookie consent manager</li>
              <li>Opting out of specific third-party cookies</li>
            </ul>
          </Typography>

          <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
            6. Updates to This Policy
          </Typography>
          <Typography variant="body1" paragraph>
            We may update this Cookie Policy from time to time to reflect changes in our practices or for operational, legal, or regulatory reasons.
          </Typography>

          <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
            7. Contact Us
          </Typography>
          <Typography variant="body1" paragraph>
            If you have questions about our Cookie Policy, please contact us at:
            <br />
            Email: privacy@vanbuur.com
            <br />
            Address: [Your Company Address]
          </Typography>
        </Paper>
      </Box>
    </Container>
  );
}

export default CookiePolicy;
