import { Card, CardContent, Skeleton, Stack } from '@mui/material';
import { keyframes } from '@mui/system';

const shimmer = keyframes`
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
`;

function PropertyCardSkeleton() {
  return (
    <Card sx={{ height: '100%', position: 'relative', overflow: 'hidden' }}>
      <Skeleton
        variant="rectangular"
        height={200}
        animation="wave"
        sx={{
          background: 'linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)',
          backgroundSize: '1000px 100%',
          animation: `${shimmer} 2s infinite linear`,
        }}
      />
      <CardContent>
        <Stack spacing={2}>
          <Skeleton variant="text" width="80%" height={32} />
          <Skeleton variant="text" width="60%" height={24} />
          <Stack direction="row" spacing={1} alignItems="center">
            <Skeleton variant="circular" width={24} height={24} />
            <Skeleton variant="text" width="70%" height={24} />
          </Stack>
          <Stack direction="row" spacing={1}>
            <Skeleton variant="rounded" width={80} height={32} />
            <Skeleton variant="rounded" width={80} height={32} />
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
}

export default PropertyCardSkeleton;
