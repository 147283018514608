import { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { collection, addDoc, doc, getDoc, updateDoc, getDocs, query, where } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { db, storage } from '../config/firebase';
import { useAuth } from '../hooks/useAuth';
import {
  Container,
  Stepper,
  Step,
  StepLabel,
  Box,
  Button,
  Typography,
  Alert,
  Grid,
  Card,
  CardMedia,
  CardContent,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent
} from '@mui/material';

import BasicDetails from '../components/property/listing/BasicDetails';
import LocationDetails from '../components/property/listing/LocationDetails';
import AmenitiesFeatures from '../components/property/listing/AmenitiesFeatures';
import ImageUpload from '../components/property/listing/ImageUpload';
import SearchBar from '../components/property/SearchBar';
import AdvancedFilters from '../components/property/AdvancedFilters';
import PropertyCard from '../components/property/PropertyCard';
import PropertyCardSkeleton from '../components/property/PropertyCardSkeleton';
import PaymentForm from '../components/payment/PaymentForm';

const steps = ['Basic Details', 'Location', 'Amenities & Features', 'Images'];

function PropertyListing({ mode = 'create' }) {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { id } = useParams();
  
  // States for browse mode
  const [properties, setProperties] = useState([]);
  const [loading, setLoading] = useState(true);
  const [allProperties, setAllProperties] = useState([]);
  const [filteredProperties, setFilteredProperties] = useState([]);
  const [viewMode, setViewMode] = useState('grid'); // State for view mode

  // States for create/edit mode
  const [activeStep, setActiveStep] = useState(0);
  const [error, setError] = useState('');
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    price: '',
    size: '',
    propertyType: '',
    street: '',
    city: '',
    state: '',
    zipCode: '',
    amenities: [],
    features: [],
    images: [],
    userId: user?.uid,
    availabilityStatus: 'Available Now',
    availableFrom: '',
    minRentalPeriod: '1',
    securityDeposit: '',
    rentalStatus: 'available',
    currentTenant: null,
    rentalHistory: []
  });

  const [paymentDialogOpen, setPaymentDialogOpen] = useState(false);
  const [uploadedImageUrls, setUploadedImageUrls] = useState([]);
  const listingFee = 29.99; // Define your listing fee

  const handlePaymentSuccess = async (paymentResponse) => {
    try {
      // Add payment information to the property data
      const propertyData = {
        ...formData,
        images: uploadedImageUrls,
        paymentStatus: 'paid',
        paymentId: paymentResponse.pspReference,
        updatedAt: new Date().toISOString()
      };

      if (mode === 'edit') {
        await updateDoc(doc(db, 'listings', id), propertyData);
      } else {
        await addDoc(collection(db, 'listings'), {
          ...propertyData,
          createdAt: new Date().toISOString()
        });
      }

      navigate('/my-properties');
    } catch (error) {
      setError('Error saving property after payment');
    }
  };

  const handlePaymentError = (error) => {
    setError('Payment failed. Please try again.');
    console.error('Payment error:', error);
  };

  const fetchPropertyData = useCallback(async () => {
    if (mode === 'edit' && id) {
      try {
        const docRef = doc(db, 'listings', id);
        const docSnap = await getDoc(docRef);
        
        if (docSnap.exists()) {
          const data = docSnap.data();
          if (data.userId !== user?.uid) {
            setError('You do not have permission to edit this property');
            return;
          }
          setFormData({ ...data });
        } else {
          setError('Property not found');
        }
      } catch (error) {
        setError('Error fetching property data');
      }
    }
  }, [id, user?.uid, mode]);

  useEffect(() => {
    fetchPropertyData();
  }, [fetchPropertyData]);

  useEffect(() => {
    if (mode === 'browse') {
      const fetchProperties = async () => {
        try {
          const querySnapshot = await getDocs(collection(db, 'listings'));
          const propertiesData = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));
          setAllProperties(propertiesData);
          setFilteredProperties(propertiesData);
        } catch (error) {
          console.error('Error fetching properties:', error);
        } finally {
          setLoading(false);
        }
      };

      fetchProperties();
    }
  }, [mode]);

  const handleSearch = (searchTerm) => {
    setLoading(true);
    const searchLower = searchTerm.toLowerCase();
    const filtered = allProperties.filter(property => {
      return (
        property.title?.toLowerCase().includes(searchLower) ||
        property.propertyType?.toLowerCase().includes(searchLower) ||
        property.city?.toLowerCase().includes(searchLower) ||
        property.state?.toLowerCase().includes(searchLower)
      );
    });
    setFilteredProperties(filtered);
    setLoading(false);
  };

  const handleFilterChange = (filters) => {
    setLoading(true);
    const filtered = allProperties.filter(property => {
      const priceInRange = property.price >= filters.priceRange[0] && 
                          property.price <= filters.priceRange[1];
      const sizeInRange = property.size >= filters.sizeRange[0] && 
                         property.size <= filters.sizeRange[1];
      const matchesType = filters.propertyTypes.length === 0 || 
                         filters.propertyTypes.includes(property.propertyType);
      
      return priceInRange && sizeInRange && matchesType;
    });
    setFilteredProperties(filtered);
    setLoading(false);
  };

  const handleViewChange = (mode) => {
    setViewMode(mode);
  };

  if (mode === 'browse') {
    return (
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Available Properties
        </Typography>
        
        <Box sx={{ mb: 4 }}>
          <SearchBar onSearch={handleSearch} />
        </Box>

        <AdvancedFilters onFilterChange={handleFilterChange} />

        <Box sx={{ mb: 2 }}>
          <Button 
            variant={viewMode === 'grid' ? 'contained' : 'outlined'} 
            onClick={() => handleViewChange('grid')}
          >
            Grid View
          </Button>
          <Button 
            variant={viewMode === 'list' ? 'contained' : 'outlined'} 
            onClick={() => handleViewChange('list')}
            sx={{ ml: 1 }}
          >
            List View
          </Button>
        </Box>

        {viewMode === 'grid' ? (
          <Grid container spacing={3}>
            {filteredProperties.map((property) => (
              <Grid item xs={12} sm={6} md={4} key={property.id}>
                <PropertyCard property={property} />
              </Grid>
            ))}
          </Grid>
        ) : (
          <Box>
            {filteredProperties.map((property) => (
              <Card key={property.id} sx={{ mb: 2, display: 'flex', flexDirection: 'row', p: 2 }}>
                <CardMedia
                  component="img"
                  sx={{ width: 200, height: 150, objectFit: 'cover', borderRadius: '8px' }}
                  image={property.images[0]}
                  alt={property.title}
                />
                <CardContent sx={{ flex: 1, ml: 2 }}>
                  <Typography variant="h6">{property.title}</Typography>
                  <Typography variant="h6" color="primary">
                    €{property.price}/month
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {property.location}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Size: {property.size} m²
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Availability: {property.availabilityStatus}
                  </Typography>
                  <Typography variant="body2" color="text.secondary" noWrap>
                    {property.description}
                  </Typography>
                </CardContent>
              </Card>
            ))}
          </Box>
        )}
      </Container>
    );
  }

  const handleNext = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleFormChange = (field, value) => {
    setFormData(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handleSubmit = async () => {
    try {
      const urls = await Promise.all(
        formData.images.map(async (image) => {
          if (typeof image === 'string') return image;
          const storageRef = ref(storage, `properties/${Date.now()}_${image.name}`);
          await uploadBytes(storageRef, image);
          return getDownloadURL(storageRef);
        })
      );
      
      setUploadedImageUrls(urls);
      setPaymentDialogOpen(true);
    } catch (error) {
      setError('Error processing images');
    }
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return <BasicDetails formData={formData} onChange={handleFormChange} />;
      case 1:
        return <LocationDetails formData={formData} onChange={handleFormChange} />;
      case 2:
        return <AmenitiesFeatures formData={formData} onChange={handleFormChange} />;
      case 3:
        return <ImageUpload formData={formData} onChange={handleFormChange} />;
      default:
        return 'Unknown step';
    }
  };

  if (error) {
    return (
      <Container maxWidth="md" sx={{ mt: 4 }}>
        <Alert severity="error">{error}</Alert>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      <Typography variant="h4" gutterBottom>
        {mode === 'edit' ? 'Edit Property' : 'List Your Property'}
      </Typography>
      <Stepper activeStep={activeStep} sx={{ mb: 4 }}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Box>
        {activeStep === steps.length ? (
          <Box sx={{ textAlign: 'center' }}>
            <Typography sx={{ mt: 2, mb: 1 }}>
              All steps completed - your property will be {mode === 'edit' ? 'updated' : 'listed'} shortly.
            </Typography>
          </Box>
        ) : (
          <Box>
            {getStepContent(activeStep)}
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
              <Button
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Back
              </Button>
              {activeStep === steps.length - 1 ? (
                <Button variant="contained" onClick={handleSubmit}>
                  {mode === 'edit' ? 'Update' : 'Submit'} Listing
                </Button>
              ) : (
                <Button variant="contained" onClick={handleNext}>
                  Next
                </Button>
              )}
            </Box>
          </Box>
        )}
      </Box>
      <Dialog open={paymentDialogOpen} onClose={() => setPaymentDialogOpen(false)}>
        <DialogTitle>Payment Required</DialogTitle>
        <DialogContent>
          <PaymentForm
            amount={listingFee}
            propertyId={id}
            onSuccess={handlePaymentSuccess}
            onError={handlePaymentError}
          />
        </DialogContent>
      </Dialog>
    </Container>
  );
}

export default PropertyListing;
