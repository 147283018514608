import { Box, Container, Typography, Grid } from '@mui/material';
import { motion } from 'framer-motion';
import HomeIcon from '@mui/icons-material/Home';
import PeopleIcon from '@mui/icons-material/People';
import LocationCityIcon from '@mui/icons-material/LocationCity';

function Stats() {
  const stats = [
    { icon: <HomeIcon sx={{ fontSize: 40 }}/>, count: '1000+', label: 'Properties Listed' },
    { icon: <PeopleIcon sx={{ fontSize: 40 }}/>, count: '5000+', label: 'Happy Users' },
    { icon: <LocationCityIcon sx={{ fontSize: 40 }}/>, count: '50+', label: 'Cities Covered' },
  ];

  return (
    <Box sx={{ bgcolor: 'primary.main', color: 'white', py: 8 }}>
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          {stats.map((stat, index) => (
            <Grid item xs={12} md={4} key={index}>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.2 }}
                viewport={{ once: true }}
              >
                <Box sx={{ textAlign: 'center' }}>
                  {stat.icon}
                  <Typography variant="h3" sx={{ my: 2, fontWeight: 'bold' }}>
                    {stat.count}
                  </Typography>
                  <Typography variant="h6">
                    {stat.label}
                  </Typography>
                </Box>
              </motion.div>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}

export default Stats;
