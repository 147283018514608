import { Box, Container, Typography, Grid } from '@mui/material';
import { motion } from 'framer-motion';
import PropertyCard from '../property/PropertyCard';

function FeaturedProperties({ properties }) {
  return (
    <Container maxWidth="lg" sx={{ py: 8 }}>
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
        viewport={{ once: true }}
      >
        <Typography
          variant="h3"
          component="h2"
          sx={{
            textAlign: 'center',
            mb: 6,
            fontWeight: 600,
          }}
        >
          Featured Properties
        </Typography>
      </motion.div>

      <Grid container spacing={4}>
        {properties.slice(0, 6).map((property, index) => (
          <Grid item xs={12} sm={6} md={4} key={property.id}>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              viewport={{ once: true }}
            >
              <PropertyCard property={property} />
            </motion.div>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}

export default FeaturedProperties;
