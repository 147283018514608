import { TextField, Grid, MenuItem } from '@mui/material';

const propertyTypes = [
  'Apartment',
  'House',
  'Studio',
  'Storage Space',
  'Garage',
  'Other'
];

const availabilityOptions = [
  'Available Now',
  'Available from Date',
  'Rented'
];

function BasicDetails({ formData, onChange }) {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TextField
          required
          fullWidth
          label="Title"
          value={formData.title}
          onChange={(e) => onChange('title', e.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          required
          fullWidth
          multiline
          rows={4}
          label="Description"
          value={formData.description}
          onChange={(e) => onChange('description', e.target.value)}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          required
          fullWidth
          type="number"
          label="Price per month (€)"
          value={formData.price}
          onChange={(e) => onChange('price', e.target.value)}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          required
          fullWidth
          type="number"
          label="Size (m²)"
          value={formData.size}
          onChange={(e) => onChange('size', e.target.value)}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          required
          fullWidth
          select
          label="Property Type"
          value={formData.propertyType}
          onChange={(e) => onChange('propertyType', e.target.value)}
        >
          {propertyTypes.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          required
          fullWidth
          select
          label="Availability Status"
          value={formData.availabilityStatus}
          onChange={(e) => onChange('availabilityStatus', e.target.value)}
        >
          {availabilityOptions.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      {formData.availabilityStatus === 'Available from Date' && (
        <Grid item xs={12} sm={6}>
          <TextField
            required
            fullWidth
            type="date"
            label="Available From"
            value={formData.availableFrom}
            onChange={(e) => onChange('availableFrom', e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
      )}
      <Grid item xs={12} sm={6}>
        <TextField
          required
          fullWidth
          type="number"
          label="Minimum Rental Period (months)"
          value={formData.minRentalPeriod}
          onChange={(e) => onChange('minRentalPeriod', e.target.value)}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          required
          fullWidth
          type="number"
          label="Security Deposit (€)"
          value={formData.securityDeposit}
          onChange={(e) => onChange('securityDeposit', e.target.value)}
        />
      </Grid>
    </Grid>
  );
}

export default BasicDetails;
