import { Container, Typography, Box, Paper } from '@mui/material';

function TermsConditions() {
  return (
    <Container maxWidth="md">
      <Box sx={{ my: 4 }}>
        <Typography variant="h3" component="h1" gutterBottom>
          Terms and Conditions
        </Typography>
        
        <Paper sx={{ p: 4 }}>
          <Typography variant="h5" gutterBottom>
            Last Updated: {new Date().toLocaleDateString()}
          </Typography>

          <Typography variant="body1" paragraph>
            These terms and conditions ("Terms") govern your use of Vanbuur B.V.'s platform. By using our services, you agree to these Terms.
          </Typography>

          <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
            1. Definitions
          </Typography>
          <Typography variant="body1" paragraph>
            <ul>
              <li>"Platform" refers to Vanbuur's website and services</li>
              <li>"User" refers to any registered individual or entity</li>
              <li>"Property" refers to storage spaces listed on the platform</li>
              <li>"Rental Agreement" refers to contracts between landlords and tenants</li>
            </ul>
          </Typography>

          <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
            2. Platform Usage
          </Typography>
          <Typography variant="body1" paragraph>
            <ul>
              <li>Users must be at least 18 years old</li>
              <li>Registration requires accurate information</li>
              <li>Users are responsible for account security</li>
              <li>Commercial use requires business registration in the Netherlands</li>
            </ul>
          </Typography>

          <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
            3. Property Listings
          </Typography>
          <Typography variant="body1" paragraph>
            Property owners must:
            <ul>
              <li>Have legal right to rent the property</li>
              <li>Comply with Dutch rental laws</li>
              <li>Provide accurate property information</li>
              <li>Maintain proper permits and insurance</li>
              <li>Report rental income to Dutch tax authorities</li>
            </ul>
          </Typography>

          <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
            4. Rental Agreements
          </Typography>
          <Typography variant="body1" paragraph>
            <ul>
              <li>Governed by Dutch rental law</li>
              <li>Must include mandatory tenant protections</li>
              <li>Security deposits limited to 3 months' rent</li>
              <li>Rent increases follow Dutch regulations</li>
            </ul>
          </Typography>

          <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
            5. Payments and Fees
          </Typography>
          <Typography variant="body1" paragraph>
            <ul>
              <li>All payments processed in Euros</li>
              <li>Platform fees clearly disclosed</li>
              <li>VAT charged where applicable</li>
              <li>Refunds per Dutch consumer law</li>
            </ul>
          </Typography>

          <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
            6. Liability
          </Typography>
          <Typography variant="body1" paragraph>
            <ul>
              <li>Platform provides matching service only</li>
              <li>Not responsible for property conditions</li>
              <li>Users must verify property suitability</li>
              <li>Insurance recommended for both parties</li>
            </ul>
          </Typography>

          <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
            7. Dispute Resolution
          </Typography>
          <Typography variant="body1" paragraph>
            <ul>
              <li>Dutch law applies exclusively</li>
              <li>Amsterdam courts have jurisdiction</li>
              <li>Mediation available through approved providers</li>
              <li>Consumer rights per EU regulations</li>
            </ul>
          </Typography>

          <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
            8. Termination
          </Typography>
          <Typography variant="body1" paragraph>
            We reserve the right to terminate access for violations of these Terms, with notice as required by Dutch law.
          </Typography>

          <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
            9. Contact Information
          </Typography>
          <Typography variant="body1" paragraph>
            Vanbuur B.V.<br />
            [Amsterdam Office Address]<br />
            Email: legal@vanbuur.com<br />
            KVK: [Number]<br />
            BTW (VAT): [Number]
          </Typography>
        </Paper>
      </Box>
    </Container>
  );
}

export default TermsConditions;
