import ReactGA from 'react-ga4';

// Initialize GA4 with your measurement ID
export const initGA = () => {
  ReactGA.initialize('G-LLMB2HTEQF'); // Replace with your GA4 measurement ID
};

// Page view tracking
export const logPageView = (path) => {
  ReactGA.send({ hitType: "pageview", page: path });
};

// Time tracking
export const startTimeTracking = (pageName) => {
  window.sessionStorage.setItem(`pageEnter_${pageName}`, Date.now().toString());
};

export const endTimeTracking = (pageName) => {
  const startTime = window.sessionStorage.getItem(`pageEnter_${pageName}`);
  if (startTime) {
    const timeSpent = Date.now() - parseInt(startTime);
    ReactGA.event({
      category: 'User Engagement',
      action: 'Time Spent',
      label: pageName,
      value: Math.floor(timeSpent / 1000) // Convert to seconds
    });
    window.sessionStorage.removeItem(`pageEnter_${pageName}`);
  }
};

// Custom event tracking
export const logEvent = (category, action, label, value) => {
  ReactGA.event({
    category,
    action,
    label,
    value
  });
};
