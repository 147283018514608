import { Container, Typography, Box, Paper, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function FAQ() {
  const faqs = [
    {
      question: "How do I list my storage space?",
      answer: "To list your storage space, click on 'List Property' in the navigation bar. Follow our step-by-step process to provide property details, location, amenities, and photos. Once submitted, your listing will be reviewed and published."
    },
    {
      question: "How does the rental process work?",
      answer: "When you find a storage space you're interested in, click 'Request to Rent' and submit your rental application. The property owner will review your request and can accept or decline. Once accepted, you'll receive payment instructions and rental agreement details."
    },
    {
      question: "What payment methods are accepted?",
      answer: "We accept major credit/debit cards, bank transfers, and other secure payment methods. All payments are processed through our secure payment system to protect both renters and property owners."
    },
    {
      question: "How are security deposits handled?",
      answer: "Security deposits are held securely during the rental period. The deposit is fully refundable within 7 days after the end of the rental period, provided there are no damages or outstanding payments."
    },
    {
      question: "What if there's a problem with my rental?",
      answer: "If you experience any issues, first try to resolve them directly with the other party. If that's not successful, contact our support team through the help center or at support@vanbuur.com. We're here to help mediate and resolve any disputes."
    },
    {
      question: "Can I cancel my rental agreement?",
      answer: "Cancellation policies vary by property and are set by the owner. Review the cancellation policy before booking. Generally, cancellations made within 48 hours of booking receive a full refund, minus service fees."
    },
    {
      question: "How do you verify users?",
      answer: "We verify users through email confirmation, phone verification, and ID verification for certain transactions. Property owners may request additional verification or references."
    },
    {
      question: "Is my property insured?",
      answer: "While we recommend property owners have appropriate insurance, Vanbuur doesn't provide direct insurance coverage. Renters should consider getting their own insurance for stored items."
    }
  ];

  return (
    <Container maxWidth="md">
      <Box sx={{ my: 4 }}>
        <Typography variant="h3" component="h1" gutterBottom>
          Frequently Asked Questions
        </Typography>
        
        <Paper sx={{ p: 4 }}>
          <Typography variant="body1" paragraph>
            Find answers to common questions about using Vanbuur. Can't find what you're looking for? Contact our support team.
          </Typography>

          {faqs.map((faq, index) => (
            <Accordion key={index} sx={{ mt: 2 }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`faq-content-${index}`}
                id={`faq-header-${index}`}
              >
                <Typography variant="h6">{faq.question}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>{faq.answer}</Typography>
              </AccordionDetails>
            </Accordion>
          ))}

          <Box sx={{ mt: 4 }}>
            <Typography variant="h6" gutterBottom>
              Still have questions?
            </Typography>
            <Typography variant="body1">
              Contact our support team at support@vanbuur.com or visit our Help Center for more detailed information.
            </Typography>
          </Box>
        </Paper>
      </Box>
    </Container>
  );
}

export default FAQ;
