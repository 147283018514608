import { useState } from 'react';
import { useAuth } from '../hooks/useAuth';
import { 
  updateProfile, 
  updateEmail, 
  updatePassword, 
  reauthenticateWithCredential,
  EmailAuthProvider 
} from 'firebase/auth';
import {
  Container,
  Box,
  TextField,
  Button,
  Typography,
  Grid,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@mui/material';
import MyRentalRequests from '../components/property/MyRentalRequests';

function Profile() {
  const { user } = useAuth();
  const [formData, setFormData] = useState({
    displayName: user?.displayName || '',
    email: user?.email || '',
    newPassword: '',
    confirmPassword: ''
  });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [openReauth, setOpenReauth] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setError('');
    setSuccess('');
  };

  const handleReauthenticate = async () => {
    try {
      const credential = EmailAuthProvider.credential(user.email, currentPassword);
      await reauthenticateWithCredential(user, credential);
      setOpenReauth(false);
      return true;
    } catch (error) {
      setError('Invalid password');
      return false;
    }
  };

  const handleUpdateProfile = async () => {
    try {
      if (formData.displayName !== user.displayName) {
        await updateProfile(user, { displayName: formData.displayName });
      }

      if (formData.email !== user.email) {
        setOpenReauth(true);
        const isReauthenticated = await handleReauthenticate();
        if (isReauthenticated) {
          await updateEmail(user, formData.email);
        }
      }

      if (formData.newPassword) {
        if (formData.newPassword !== formData.confirmPassword) {
          setError('New passwords do not match');
          return;
        }
        setOpenReauth(true);
        const isReauthenticated = await handleReauthenticate();
        if (isReauthenticated) {
          await updatePassword(user, formData.newPassword);
        }
      }

      setSuccess('Profile updated successfully');
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <Container component="main" maxWidth="md">
      <Box sx={{ mt: 4, mb: 4 }}>
        <Typography variant="h4" gutterBottom>
          Profile Settings
        </Typography>
        <Paper sx={{ p: 3 }}>
          {error && <Typography color="error" gutterBottom>{error}</Typography>}
          {success && <Typography color="success.main" gutterBottom>{success}</Typography>}
          
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                name="displayName"
                label="Display Name"
                value={formData.displayName}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                name="email"
                label="Email"
                type="email"
                value={formData.email}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                Change Password
              </Typography>
              <TextField
                fullWidth
                name="newPassword"
                label="New Password"
                type="password"
                value={formData.newPassword}
                onChange={handleChange}
                sx={{ mb: 2 }}
              />
              <TextField
                fullWidth
                name="confirmPassword"
                label="Confirm New Password"
                type="password"
                value={formData.confirmPassword}
                onChange={handleChange}
              />
            </Grid>
          </Grid>

          <Button
            variant="contained"
            onClick={handleUpdateProfile}
            sx={{ mt: 3 }}
          >
            Update Profile
          </Button>
        </Paper>
      </Box>

      <Dialog open={openReauth} onClose={() => setOpenReauth(false)}>
        <DialogTitle>Verify Your Identity</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            label="Current Password"
            type="password"
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
            sx={{ mt: 1 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenReauth(false)}>Cancel</Button>
          <Button onClick={handleReauthenticate} variant="contained">
            Verify
          </Button>
        </DialogActions>
      </Dialog>

      <Box sx={{ mt: 4 }}>
        <MyRentalRequests />
      </Box>
    </Container>
  );
}

export default Profile;
