import { useState } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  Box,
  Alert,
  Snackbar
} from '@mui/material';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../../config/firebase';
import { useAuth } from '../../hooks/useAuth';

function RentalRequest({ property, open, onClose }) {
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [formData, setFormData] = useState({
    startDate: '',
    rentalPeriod: '',
    message: ''
  });

  const handleSubmit = async () => {
    setLoading(true);
    setError('');

    // Debug log
    console.log('Property data:', property);
    console.log('User data:', user);
    console.log('Form data:', formData);

    try {
      // Validate required data
      if (!property || !property.id || !user || !user.uid) {
        throw new Error('Missing required property or user data');
      }

      const requestData = {
        propertyId: property.id,
        propertyTitle: String(property.title || ''), // Ensure string
        landlordId: String(property.userId || ''), // Ensure string
        tenantId: String(user.uid),
        tenantEmail: String(user.email || ''),
        tenantName: String(user.displayName || ''),
        status: 'pending',
        startDate: formData.startDate || new Date().toISOString(),
        rentalPeriod: Number(formData.rentalPeriod) || 1,
        message: String(formData.message || ''),
        monthlyRent: Number(property.price) || 0,
        securityDeposit: Number(property.securityDeposit) || 0,
        createdAt: serverTimestamp()
      };

      // Debug log
      console.log('Request data to be submitted:', requestData);

      // Create the rental request
      const docRef = await addDoc(collection(db, 'rentalRequests'), requestData);
      console.log('Document written with ID: ', docRef.id);

      setSuccess(true);
      setFormData({
        startDate: '',
        rentalPeriod: '',
        message: ''
      });

      setTimeout(() => {
        onClose();
        setSuccess(false);
      }, 2000);

    } catch (error) {
      console.error('Error submitting rental request:', error);
      setError(`Failed to submit request: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  // Add validation check before rendering
  if (!property || !property.id) {
    return null;
  }

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
        <DialogTitle>Request to Rent</DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 2 }}>
            {error && (
              <Alert severity="error" sx={{ mb: 2 }}>
                {error}
              </Alert>
            )}
            
            <Typography variant="subtitle1" gutterBottom>
              Property: {property?.title || 'N/A'}
            </Typography>
            <Typography variant="body2" color="text.secondary" gutterBottom>
              €{property?.price || 0}/month
            </Typography>
            
            <TextField
              required
              fullWidth
              label="Desired Start Date"
              type="date"
              value={formData.startDate}
              onChange={(e) => setFormData({ ...formData, startDate: e.target.value })}
              sx={{ mt: 2 }}
              InputLabelProps={{ shrink: true }}
              inputProps={{ min: new Date().toISOString().split('T')[0] }}
            />
            
            <TextField
              required
              fullWidth
              label="Rental Period (months)"
              type="number"
              value={formData.rentalPeriod}
              onChange={(e) => setFormData({ ...formData, rentalPeriod: e.target.value })}
              sx={{ mt: 2 }}
              inputProps={{ min: 1 }}
            />
            
            <TextField
              fullWidth
              label="Message to Landlord"
              multiline
              rows={4}
              value={formData.message}
              onChange={(e) => setFormData({ ...formData, message: e.target.value })}
              sx={{ mt: 2 }}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} disabled={loading}>
            Cancel
          </Button>
          <Button 
            onClick={handleSubmit} 
            variant="contained"
            disabled={loading}
          >
            {loading ? 'Submitting...' : 'Submit Request'}
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={success}
        autoHideDuration={2000}
        onClose={() => setSuccess(false)}
      >
        <Alert severity="success" sx={{ width: '100%' }}>
          Rental request submitted successfully!
        </Alert>
      </Snackbar>
    </>
  );
}

export default RentalRequest;
