import { Box, Container, Typography, Button } from '@mui/material';
import { motion, useScroll, useTransform } from 'framer-motion';
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';

function HeroSection() {
  const theme = useTheme();
  const { scrollY } = useScroll();
  const y = useTransform(scrollY, [0, 500], [0, 150]);

  return (
    <Box
      sx={{
        position: 'relative',
        height: '90vh',
        display: 'flex',
        alignItems: 'center',
        background: 'linear-gradient(45deg, #1a237e 30%, #0d47a1 90%)',
        overflow: 'hidden',
      }}
    >
      {/* Animated particles */}
      {[...Array(30)].map((_, i) => (
        <motion.div
          key={i}
          initial={{ opacity: 0.1, scale: 0 }}
          animate={{
            opacity: [0.1, 0.3, 0.1],
            scale: [1, 2, 1],
            x: [0, Math.random() * 200 - 100],
            y: [0, Math.random() * 200 - 100],
          }}
          transition={{
            duration: 10,
            repeat: Infinity,
            delay: i * 0.2,
          }}
          style={{
            position: 'absolute',
            width: '20px',
            height: '20px',
            borderRadius: '50%',
            background: 'rgba(255, 255, 255, 0.1)',
            left: `${Math.random() * 100}%`,
            top: `${Math.random() * 100}%`,
          }}
        />
      ))}

      {/* Parallax Background Images */}
      <motion.div
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          y,
        }}
      >
        <Box
          component="img"
          src="/images/city-silhouette.png"
          alt="City Silhouette"
          sx={{
            position: 'absolute',
            bottom: 0,
            width: '100%',
            height: '30%',
            objectFit: 'cover',
            opacity: 0.2,
          }}
        />
      </motion.div>

      <Container maxWidth="lg" sx={{ position: 'relative', zIndex: 2 }}>
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
        >
          <Typography
            variant="h1"
            sx={{
              color: 'white',
              fontWeight: 800,
              fontSize: { xs: '2.5rem', md: '4.5rem' },
              textAlign: 'center',
              mb: 3,
              textShadow: '2px 2px 4px rgba(0,0,0,0.3)',
            }}
          >
            Find Your Perfect Storage Space
          </Typography>

          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.5 }}
          >
            <Typography
              variant="h5"
              sx={{
                color: 'rgba(255, 255, 255, 0.9)',
                textAlign: 'center',
                mb: 8,
                maxWidth: '800px',
                mx: 'auto',
              }}
            >
              Connect with local storage spaces or share your extra space with others
            </Typography>
          </motion.div>

          <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, flexWrap: 'wrap' }}>
            <motion.div
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              <Button
                component={Link}
                to="/properties"
                variant="contained"
                size="large"
                sx={{
                  height: { xs: '48px', md: '56px' },
                  px: { xs: 4, md: 6 },
                  fontSize: { xs: '1rem', md: '1.25rem' },
                  backgroundColor: 'white',
                  color: 'primary.main',
                  '&:hover': {
                    backgroundColor: 'rgba(255, 255, 255, 0.9)',
                  },
                  boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
                  width: { xs: '200px', md: 'auto' }
                }}
              >
                Find a Space
              </Button>
            </motion.div>
            
            <motion.div
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              <Button
                component={Link}
                to="/property/new"
                variant="outlined"
                size="large"
                sx={{
                  height: { xs: '48px', md: '56px' },
                  px: { xs: 4, md: 6 },
                  fontSize: { xs: '1rem', md: '1.25rem' },
                  color: 'white',
                  borderColor: 'white',
                  borderWidth: 2,
                  '&:hover': {
                    borderColor: 'white',
                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                  },
                  width: { xs: '200px', md: 'auto' }
                }}
              >
                List Your Space
              </Button>
            </motion.div>
          </Box>
        </motion.div>
      </Container>
    </Box>
  );
}

export default HeroSection;
