import { useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../config/firebase';
import { Container, Grid, Typography, Skeleton, Box } from '@mui/material';
import PropertyCard from '../components/property/PropertyCard';
import SearchBar from '../components/property/SearchBar';
import PropertyCardSkeleton from '../components/property/PropertyCardSkeleton';
// import FloatingActionButton from '../components/common/FloatingActionButton';
import AdvancedFilters from '../components/property/AdvancedFilters';
import { motion } from 'framer-motion';
import FeaturedProperties from '../components/home/FeaturedProperties';
import Stats from '../components/home/Stats';
import HeroSection from '../components/home/HeroSection';
import LoadingScreen from '../components/common/LoadingScreen';
import HowItWorks from '../components/home/HowItWorks';
import Testimonials from '../components/home/Testimonials';
import Benefits from '../components/home/Benefits';
import UserBenefits from '../components/home/UserBenefits';
import { usePageTracking } from '../hooks/usePageTracking';
import { logEvent } from '../utils/analytics';

function Home() {
  usePageTracking('Home');
  
  const [properties, setProperties] = useState([]);
  const [filteredProperties, setFilteredProperties] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProperties = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'listings'));
        const propertiesData = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setProperties(propertiesData);
        setFilteredProperties(propertiesData);
      } catch (error) {
        console.error('Error fetching properties:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchProperties();
  }, []);

  const handleSearch = (searchTerm) => {
    logEvent('Search', 'Property Search', searchTerm);
    const searchLower = searchTerm.toLowerCase();
    const filtered = properties.filter(property => {
      return (
        property.title?.toLowerCase().includes(searchLower) ||
        property.propertyType?.toLowerCase().includes(searchLower) ||
        property.city?.toLowerCase().includes(searchLower) ||
        property.state?.toLowerCase().includes(searchLower) ||
        property.street?.toLowerCase().includes(searchLower) ||
        property.zipCode?.includes(searchTerm)
      );
    });
    setFilteredProperties(filtered);
  };

  const applyFilters = (properties, filters) => {
    return properties.filter(property => {
      const matchesPrice = property.price >= filters.priceRange[0] && 
                          property.price <= filters.priceRange[1];
      
      const matchesSize = property.size >= filters.sizeRange[0] && 
                         property.size <= filters.sizeRange[1];
      
      const matchesType = filters.propertyTypes.length === 0 || 
                         filters.propertyTypes.includes(property.propertyType);
      
      const matchesLocation = !filters.location || 
                             property.city?.toLowerCase().includes(filters.location.toLowerCase()) ||
                             property.state?.toLowerCase().includes(filters.location.toLowerCase());

      return matchesPrice && matchesSize && matchesType && matchesLocation;
    });
  };

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <Box>
      <HeroSection />
      <Stats />
      <Benefits />
      <UserBenefits />
      <Testimonials />
      <FeaturedProperties properties={properties} />
    </Box>
  );
}

export default Home;
