import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../config/firebase';

export const createNotification = async (userId, type, message, metadata = {}) => {
  try {
    console.log('Creating notification:', { userId, type, message, metadata });
    
    const notificationData = {
      userId,
      type,
      message,
      read: false,
      createdAt: serverTimestamp(),
      ...metadata
    };

    const docRef = await addDoc(collection(db, 'notifications'), notificationData);
    console.log('Notification created with ID:', docRef.id);
    
    return docRef.id;
  } catch (error) {
    console.error('Error creating notification:', error);
    throw error;
  }
};
