import { Box, Container, Typography, Grid } from '@mui/material';
import { motion } from 'framer-motion';
import RecyclingIcon from '@mui/icons-material/Recycling';
import SavingsIcon from '@mui/icons-material/Savings';
import SecurityIcon from '@mui/icons-material/Security';
import GroupsIcon from '@mui/icons-material/Groups';

function Benefits() {
  const benefits = [
    {
      icon: <RecyclingIcon sx={{ fontSize: 60 }} />,
      title: "Sustainable Storage",
      value: "30%",
      description: "Reduced Carbon Footprint",
      color: "#4CAF50"
    },
    {
      icon: <SavingsIcon sx={{ fontSize: 60 }} />,
      title: "Cost Effective",
      value: "€250",
      description: "Average Monthly Savings",
      color: "#2196F3"
    },
    {
      icon: <SecurityIcon sx={{ fontSize: 60 }} />,
      title: "Secure & Safe",
      value: "100%",
      description: "Verified Properties",
      color: "#9C27B0"
    },
    {
      icon: <GroupsIcon sx={{ fontSize: 60 }} />,
      title: "Community Driven",
      value: "5000+",
      description: "Active Members",
      color: "#FF9800"
    }
  ];

  return (
    <Box sx={{ py: 8, bgcolor: 'background.paper' }}>
      <Container maxWidth="lg">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          viewport={{ once: true }}
        >
          <Typography
            variant="h3"
            component="h2"
            sx={{
              textAlign: 'center',
              mb: 8,
              fontWeight: 600,
            }}
          >
            Why Choose Vanbuur
          </Typography>
        </motion.div>

        <Grid container spacing={4}>
          {benefits.map((benefit, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.2 }}
                viewport={{ once: true }}
              >
                <Box
                  sx={{
                    textAlign: 'center',
                    p: 3,
                    height: '100%',
                    position: 'relative',
                  }}
                >
                  <motion.div
                    whileHover={{ scale: 1.1, rotate: 5 }}
                    transition={{ duration: 0.3 }}
                  >
                    <Box
                      sx={{
                        color: benefit.color,
                        mb: 2,
                        display: 'inline-block',
                      }}
                    >
                      {benefit.icon}
                    </Box>
                  </motion.div>
                  
                  <Typography
                    variant="h4"
                    sx={{
                      fontWeight: 'bold',
                      color: benefit.color,
                      mb: 1
                    }}
                  >
                    {benefit.value}
                  </Typography>
                  
                  <Typography
                    variant="h6"
                    sx={{ mb: 1, fontWeight: 600 }}
                  >
                    {benefit.title}
                  </Typography>
                  
                  <Typography
                    variant="body2"
                    color="text.secondary"
                  >
                    {benefit.description}
                  </Typography>
                </Box>
              </motion.div>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}

export default Benefits;
