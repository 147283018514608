import { useState } from 'react';
import { 
  Box, 
  Button, 
  Typography,
  IconButton,
  ImageList,
  ImageListItem
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

function ImageUpload({ formData, onChange }) {
  const [previewUrls, setPreviewUrls] = useState([]);

  const handleImageUpload = (event) => {
    const files = Array.from(event.target.files);
    const newImages = [...formData.images, ...files];
    onChange('images', newImages);

    // Create preview URLs for the images
    const newPreviewUrls = files.map(file => URL.createObjectURL(file));
    setPreviewUrls(prev => [...prev, ...newPreviewUrls]);
  };

  const handleRemoveImage = (index) => {
    const newImages = formData.images.filter((_, i) => i !== index);
    onChange('images', newImages);
    
    // Remove preview URL and revoke object URL
    URL.revokeObjectURL(previewUrls[index]);
    const newPreviewUrls = previewUrls.filter((_, i) => i !== index);
    setPreviewUrls(newPreviewUrls);
  };

  return (
    <Box>
      <input
        accept="image/*"
        style={{ display: 'none' }}
        id="image-upload"
        multiple
        type="file"
        onChange={handleImageUpload}
      />
      <label htmlFor="image-upload">
        <Button
          variant="contained"
          component="span"
          startIcon={<CloudUploadIcon />}
        >
          Upload Images
        </Button>
      </label>
      
      <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
        Upload up to 10 images of your property
      </Typography>

      <ImageList sx={{ mt: 2 }} cols={3} rowHeight={200}>
        {previewUrls.map((url, index) => (
          <ImageListItem key={index}>
            <img src={url} alt={`Property ${index + 1}`} loading="lazy" />
            <IconButton
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                bgcolor: 'rgba(0, 0, 0, 0.5)',
                '&:hover': { bgcolor: 'rgba(0, 0, 0, 0.7)' },
              }}
              onClick={() => handleRemoveImage(index)}
            >
              <DeleteIcon sx={{ color: 'white' }} />
            </IconButton>
          </ImageListItem>
        ))}
      </ImageList>
    </Box>
  );
}

export default ImageUpload;
