import { useState, useEffect } from 'react';
import {
  Container,
  Grid,
  Paper,
  Typography,
  Box,
  Tabs,
  Tab,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField
} from '@mui/material';
import { collection, query, where, getDocs, updateDoc, doc, addDoc, getDoc, onSnapshot } from 'firebase/firestore';
import { db } from '../config/firebase';
import { useAuth } from '../hooks/useAuth';
import { createNotification } from '../utils/notificationUtils';

function LandlordDashboard() {
  const { user } = useAuth();
  const [activeTab, setActiveTab] = useState(0);
  const [requests, setRequests] = useState([]);
  const [contractDialog, setContractDialog] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [contractDetails, setContractDetails] = useState({
    startDate: '',
    endDate: '',
    monthlyRent: '',
    securityDeposit: '',
    specialTerms: ''
  });
  const [viewContractDialog, setViewContractDialog] = useState(false);
  const [selectedContract, setSelectedContract] = useState(null);

  useEffect(() => {
    const q = query(
      collection(db, 'rentalRequests'),
      where('landlordId', '==', user.uid)
    );

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const requestsData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setRequests(requestsData);
    }, (error) => {
      console.error('Error fetching requests:', error);
    });

    return () => unsubscribe();
  }, [user.uid]);

  const handleApprove = async (request) => {
    setSelectedRequest(request);
    setContractDetails({
      startDate: request.startDate,
      endDate: new Date(new Date(request.startDate).setMonth(
        new Date(request.startDate).getMonth() + Number(request.rentalPeriod)
      )).toISOString().split('T')[0],
      monthlyRent: request.monthlyRent,
      securityDeposit: request.securityDeposit,
      specialTerms: ''
    });
    setContractDialog(true);
  };

  const handleGenerateContract = async () => {
    try {
      // Create rental contract
      const contractRef = await addDoc(collection(db, 'rentalContracts'), {
        requestId: selectedRequest.id,
        propertyId: selectedRequest.propertyId,
        propertyTitle: selectedRequest.propertyTitle,
        landlordId: user.uid,
        tenantId: selectedRequest.tenantId,
        ...contractDetails,
        status: 'pending_tenant_signature',
        createdAt: new Date().toISOString()
      });

      // Update request status
      await updateDoc(doc(db, 'rentalRequests', selectedRequest.id), {
        status: 'approved',
        contractId: contractRef.id
      });

      // Update property status
      await updateDoc(doc(db, 'listings', selectedRequest.propertyId), {
        availabilityStatus: 'Contract Pending',
        currentTenant: selectedRequest.tenantId
      });

      // Create notification for tenant
      await createNotification(
        selectedRequest.tenantId,
        'contract_ready',
        `Your rental application for ${selectedRequest.propertyTitle} has been approved. Please review and sign the contract.`,
        {
          propertyId: selectedRequest.propertyId,
          contractId: contractRef.id
        }
      );

      console.log('Contract approval notification sent');
      setContractDialog(false);

    } catch (error) {
      console.error('Error in handleGenerateContract:', error);
    }
  };

  const handleReject = async (requestId) => {
    try {
      const request = requests.find(r => r.id === requestId);
      await updateDoc(doc(db, 'rentalRequests', requestId), {
        status: 'rejected'
      });

      // Create notification for tenant
      await createNotification(
        request.tenantId,
        'application_rejected',
        `Your rental application for ${request.propertyTitle} has been rejected.`,
        {
          propertyId: request.propertyId
        }
      );

      console.log('Rejection notification sent');
    } catch (error) {
      console.error('Error in handleReject:', error);
    }
  };

  const handleViewContract = async (contractId) => {
    try {
      const contractDoc = await getDoc(doc(db, 'rentalContracts', contractId));
      if (contractDoc.exists()) {
        setSelectedContract({ id: contractDoc.id, ...contractDoc.data() });
        setViewContractDialog(true);
      }
    } catch (error) {
      console.error('Error fetching contract:', error);
    }
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4" gutterBottom>
        Landlord Dashboard
      </Typography>

      <Paper sx={{ width: '100%', mb: 2 }}>
        <Tabs value={activeTab} onChange={(e, newValue) => setActiveTab(newValue)}>
          <Tab label="Pending Requests" />
          <Tab label="Active Contracts" />
          <Tab label="Request History" />
        </Tabs>

        <Box sx={{ p: 3 }}>
          {activeTab === 0 && (
            <Grid container spacing={3}>
              {requests
                .filter(request => request.status === 'pending')
                .map(request => (
                  <Grid item xs={12} key={request.id}>
                    <Paper sx={{ p: 2 }}>
                      <Typography variant="h6">{request.propertyTitle}</Typography>
                      <Typography>From: {request.tenantEmail}</Typography>
                      <Typography>Start Date: {new Date(request.startDate).toLocaleDateString()}</Typography>
                      <Typography>Rental Period: {request.rentalPeriod} months</Typography>
                      <Typography>Message: {request.message}</Typography>
                      <Box sx={{ mt: 2 }}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => handleApprove(request)}
                          sx={{ mr: 1 }}
                        >
                          Approve
                        </Button>
                        <Button
                          variant="outlined"
                          color="error"
                          onClick={() => handleReject(request.id)}
                        >
                          Reject
                        </Button>
                      </Box>
                    </Paper>
                  </Grid>
                ))}
            </Grid>
          )}

          {activeTab === 1 && (
            <Grid container spacing={3}>
              {requests
                .filter(request => request.status === 'approved' && request.contractId)
                .map(request => (
                  <Grid item xs={12} key={request.id}>
                    <Paper sx={{ p: 2 }}>
                      <Typography variant="h6">{request.propertyTitle}</Typography>
                      <Typography>Tenant: {request.tenantEmail}</Typography>
                      <Typography>Start Date: {new Date(request.startDate).toLocaleDateString()}</Typography>
                      <Typography>Rental Period: {request.rentalPeriod} months</Typography>
                      <Typography>Monthly Rent: €{request.monthlyRent}</Typography>
                      <Box sx={{ mt: 2 }}>
                        <Button
                          variant="outlined"
                          onClick={() => handleViewContract(request.contractId)}
                        >
                          View Contract
                        </Button>
                      </Box>
                    </Paper>
                  </Grid>
                ))}
            </Grid>
          )}

          {activeTab === 2 && (
            <Grid container spacing={3}>
              {requests
                .filter(request => request.status === 'rejected')
                .map(request => (
                  <Grid item xs={12} key={request.id}>
                    <Paper sx={{ p: 2 }}>
                      <Typography variant="h6">{request.propertyTitle}</Typography>
                      <Typography>From: {request.tenantEmail}</Typography>
                      <Typography>Submitted: {new Date(request.createdAt).toLocaleDateString()}</Typography>
                      <Typography>Status: Rejected</Typography>
                    </Paper>
                  </Grid>
                ))}
            </Grid>
          )}
        </Box>
      </Paper>

      <Dialog open={contractDialog} onClose={() => setContractDialog(false)} maxWidth="md" fullWidth>
        <DialogTitle>Generate Rental Contract</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Start Date"
                type="date"
                value={contractDetails.startDate}
                onChange={(e) => setContractDetails({
                  ...contractDetails,
                  startDate: e.target.value
                })}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="End Date"
                type="date"
                value={contractDetails.endDate}
                onChange={(e) => setContractDetails({
                  ...contractDetails,
                  endDate: e.target.value
                })}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                rows={4}
                label="Special Terms and Conditions"
                value={contractDetails.specialTerms}
                onChange={(e) => setContractDetails({
                  ...contractDetails,
                  specialTerms: e.target.value
                })}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setContractDialog(false)}>Cancel</Button>
          <Button onClick={handleGenerateContract} variant="contained">
            Generate Contract
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={viewContractDialog} onClose={() => setViewContractDialog(false)} maxWidth="md" fullWidth>
        <DialogTitle>Contract Details</DialogTitle>
        <DialogContent>
          {selectedContract && (
            <Box sx={{ mt: 2 }}>
              <Typography variant="h6" gutterBottom>
                {selectedContract.propertyTitle}
              </Typography>
              <Typography><strong>Start Date:</strong> {new Date(selectedContract.startDate).toLocaleDateString()}</Typography>
              <Typography><strong>End Date:</strong> {new Date(selectedContract.endDate).toLocaleDateString()}</Typography>
              <Typography><strong>Monthly Rent:</strong> €{selectedContract.monthlyRent}</Typography>
              <Typography><strong>Security Deposit:</strong> €{selectedContract.securityDeposit}</Typography>
              {selectedContract.specialTerms && (
                <>
                  <Typography variant="h6" sx={{ mt: 3, mb: 1 }}>
                    Special Terms and Conditions
                  </Typography>
                  <Typography>{selectedContract.specialTerms}</Typography>
                </>
              )}
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setViewContractDialog(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default LandlordDashboard;
