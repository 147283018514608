import { useState } from 'react';
import {
  Box,
  Paper,
  Typography,
  Slider,
  TextField,
  Autocomplete,
  Grid,
  Chip,
  Button,
  Collapse,
  IconButton
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const propertyTypes = ['Apartment', 'House', 'Studio', 'Storage Space', 'Garage', 'Other'];

function AdvancedFilters({ onFilterChange }) {
  const [expanded, setExpanded] = useState(false);
  const [filters, setFilters] = useState({
    priceRange: [0, 1000],
    sizeRange: [0, 100],
    propertyTypes: [],
    amenities: [],
    features: [],
    location: null
  });

  const handleFilterChange = (field, value) => {
    const newFilters = { ...filters, [field]: value };
    setFilters(newFilters);
    onFilterChange(newFilters);
  };

  return (
    <Paper sx={{ p: 2, mb: 3 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography variant="h6">Advanced Filters</Typography>
        <IconButton onClick={() => setExpanded(!expanded)}>
          {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      </Box>

      <Collapse in={expanded}>
        <Grid container spacing={3} sx={{ mt: 1 }}>
          <Grid item xs={12} md={6}>
            <Typography gutterBottom>Price Range (€/month)</Typography>
            <Slider
              value={filters.priceRange}
              onChange={(_, value) => handleFilterChange('priceRange', value)}
              valueLabelDisplay="auto"
              min={0}
              max={1000}
              step={100}
            />
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant="body2">€{filters.priceRange[0]}</Typography>
              <Typography variant="body2">€{filters.priceRange[1]}</Typography>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography gutterBottom>Size Range (m²)</Typography>
            <Slider
              value={filters.sizeRange}
              onChange={(_, value) => handleFilterChange('sizeRange', value)}
              valueLabelDisplay="auto"
              min={0}
              max={100}
              step={1}
            />
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant="body2">{filters.sizeRange[0]} m²</Typography>
              <Typography variant="body2">{filters.sizeRange[1]} m²</Typography>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Autocomplete
              multiple
              options={propertyTypes}
              value={filters.propertyTypes}
              onChange={(_, value) => handleFilterChange('propertyTypes', value)}
              renderInput={(params) => (
                <TextField {...params} label="Property Types" />
              )}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip label={option} {...getTagProps({ index })} />
                ))
              }
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Location"
              onChange={(e) => handleFilterChange('location', e.target.value)}
            />
          </Grid>
        </Grid>

        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
          <Button
            variant="outlined"
            onClick={() => {
              setFilters({
                priceRange: [0, 1000],
                sizeRange: [0, 100],
                propertyTypes: [],
                amenities: [],
                features: [],
                location: null
              });
              onFilterChange({});
            }}
            sx={{ mr: 1 }}
          >
            Reset Filters
          </Button>
        </Box>
      </Collapse>
    </Paper>
  );
}

export default AdvancedFilters;
