import { Box, Container, Typography, Paper, Avatar } from '@mui/material';
import { motion } from 'framer-motion';

function Testimonials() {
  const testimonials = [
    {
      name: "Sarah Johnson",
      role: "Storage Owner",
      text: "Vanbuur has transformed the way I manage my extra storage space. It's now generating income I never thought possible!",
      avatar: "/images/avatar1.jpg"
    },
    {
      name: "Michael Chen",
      role: "Renter",
      text: "Finding storage space was a breeze with Vanbuur. The process is simple, and the spaces are exactly as described.",
      avatar: "/images/avatar2.jpg"
    },
    {
      name: "Emma Williams",
      role: "Business Owner",
      text: "As a small business owner, Vanbuur has been invaluable for managing our seasonal storage needs.",
      avatar: "/images/avatar3.jpg"
    }
  ];

  return (
    <Box sx={{ py: 8, bgcolor: 'background.default' }}>
      <Container maxWidth="lg">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          viewport={{ once: true }}
        >
          <Typography
            variant="h3"
            component="h2"
            sx={{
              textAlign: 'center',
              mb: 8,
              fontWeight: 600,
            }}
          >
            What Our Users Say
          </Typography>
        </motion.div>

        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: 4,
            justifyContent: 'center',
          }}
        >
          {testimonials.map((testimonial, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.2 }}
              viewport={{ once: true }}
            >
              <Paper
                elevation={2}
                sx={{
                  p: 4,
                  maxWidth: 350,
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  textAlign: 'center',
                  '&:hover': {
                    transform: 'translateY(-8px)',
                    transition: 'transform 0.3s ease-in-out',
                  },
                }}
              >
                <Avatar
                  src={testimonial.avatar}
                  sx={{ width: 80, height: 80, mb: 2 }}
                />
                <Typography
                  variant="body1"
                  sx={{ mb: 2, fontStyle: 'italic' }}
                >
                  "{testimonial.text}"
                </Typography>
                <Typography variant="h6" sx={{ mb: 1 }}>
                  {testimonial.name}
                </Typography>
                <Typography variant="subtitle2" color="text.secondary">
                  {testimonial.role}
                </Typography>
              </Paper>
            </motion.div>
          ))}
        </Box>
      </Container>
    </Box>
  );
}

export default Testimonials;
