import React, { useEffect, useRef, useState } from 'react';
import { initializeAdyenCheckout } from '../../services/payment';
import { Box, CircularProgress, Typography, Alert } from '@mui/material';
import { AdyenCheckout, Dropin, Card, PayPal, GooglePay, ApplePay } from '@adyen/adyen-web';

function PaymentForm({ amount, propertyId, onSuccess, onError }) {
  const checkoutRef = useRef(null);
  const [checkout, setCheckout] = useState(null);
  const [loading, setLoading] = useState(true);
  const [paymentError, setPaymentError] = useState('');

  useEffect(() => {
    const setupAdyenCheckout = async () => {
      console.log('🔄 Setting up Adyen Checkout...', { amount, propertyId });
      try {
        const adyenCheckout = await initializeAdyenCheckout(amount);
        console.log('✅ Adyen Checkout initialized:', adyenCheckout);
        setCheckout(adyenCheckout);
        setLoading(false);
      } catch (error) {
        console.error('❌ Error initializing Adyen Checkout:', error);
        setPaymentError('Failed to initialize payment. Please try again later.');
        setLoading(false);
        if (onError) {
          onError(error);
        }
      }
    };

    setupAdyenCheckout();
  }, [amount, onError]);

  useEffect(() => {
    if (checkout && checkoutRef.current) {
      console.log('🔄 Unmounting previous checkout instance');
      checkoutRef.current.unmount();
    }

    const dropinConfiguration = {
      paymentMethodsConfiguration: {
        card: {
          hasHolderName: true,
          holderNameRequired: true,
          onError: (error) => {
            console.error('❌ Card configuration error:', error);
          }
        }
      },
      paymentMethodComponents: [Card, PayPal, GooglePay, ApplePay],
      instantPaymentTypes: ['googlepay', 'applepay'],
      onReady: () => {
        console.log('✅ Drop-in component ready');
      },
      onSubmit: async (state, component) => {
        console.log('🔄 Payment submission started:', state);
        try {
          if (state.isValid) {
            console.log('✅ Payment state valid, sending request:', {
              paymentMethod: state.data.paymentMethod,
              amount,
              propertyId
            });

            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/payments`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                paymentMethod: state.data.paymentMethod,
                amount: amount,
                propertyId: propertyId
              }),
            });

            const result = await response.json();
            console.log('📦 Payment response received:', result);
            
            if (result.resultCode === "Authorised") {
              console.log('✅ Payment authorized');
              if (onSuccess) {
                onSuccess(result);
              }
              component.setStatus('success');
            } else {
              console.error('❌ Payment not authorized:', result.resultCode);
              throw new Error(result.resultCode || 'Payment failed');
            }
          }
        } catch (error) {
          console.error('❌ Payment submission error:', error);
          setPaymentError('Payment failed. Please try again.');
          component.setStatus('error');
          if (onError) {
            onError(error);
          }
        }
      },
      onError: (error) => {
        console.error('❌ Adyen Drop-in error:', error);
        setPaymentError('An error occurred during the payment process.');
        if (onError) {
          onError(error);
        }
      }
    };
    
    if (checkout && checkoutRef.current === null) {
      console.log('🔄 Mounting new Drop-in instance');
      const dropin = new Dropin(checkout, dropinConfiguration).mount('#payment-container');
      console.log('✅ Drop-in mounted successfully');
      checkoutRef.current = dropin;
    }
  }, [checkout, onSuccess, onError]);

  return (
    <Box>
      {paymentError && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {paymentError}
        </Alert>
      )}
      <div id="payment-container"></div>
    </Box>
  );
}

export default PaymentForm;
