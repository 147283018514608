import { useState } from 'react';
import { 
  Dialog, 
  IconButton, 
  Box,
  ImageList,
  ImageListItem,
  MobileStepper,
  Button 
} from '@mui/material';
import {
  Close as CloseIcon,
  KeyboardArrowLeft,
  KeyboardArrowRight
} from '@mui/icons-material';

function PropertyGallery({ images }) {
  const [open, setOpen] = useState(false);
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  return (
    <>
      <ImageList cols={3} rowHeight={300} gap={16} sx={{ overflow: 'hidden' }}>
        {images.map((image, index) => (
          <ImageListItem 
            key={index}
            onClick={() => {
              setActiveStep(index);
              setOpen(true);
            }}
            sx={{ 
              cursor: 'pointer',
              '&:hover': {
                '& img': {
                  transform: 'scale(1.05)',
                },
                '&::after': {
                  content: '""',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: 'rgba(0,0,0,0.2)',
                }
              }
            }}
          >
            <img
              src={image}
              alt={`Property ${index + 1}`}
              style={{
                height: '100%',
                width: '100%',
                objectFit: 'cover',
                transition: 'transform 0.3s ease'
              }}
            />
          </ImageListItem>
        ))}
      </ImageList>

      <Dialog
        fullScreen
        open={open}
        onClose={() => setOpen(false)}
      >
        <Box sx={{ 
          height: '100%', 
          display: 'flex', 
          flexDirection: 'column',
          bgcolor: 'black' 
        }}>
          <IconButton
            onClick={() => setOpen(false)}
            sx={{ 
              position: 'absolute', 
              right: 8, 
              top: 8,
              color: 'white'
            }}
          >
            <CloseIcon />
          </IconButton>

          <Box sx={{ 
            flexGrow: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
            <img
              src={images[activeStep]}
              alt={`Property ${activeStep + 1}`}
              style={{
                maxHeight: '90vh',
                maxWidth: '90vw',
                objectFit: 'contain'
              }}
            />
          </Box>

          <MobileStepper
            steps={images.length}
            position="static"
            activeStep={activeStep}
            sx={{ 
              bgcolor: 'transparent',
              color: 'white'
            }}
            nextButton={
              <Button
                size="small"
                onClick={handleNext}
                disabled={activeStep === images.length - 1}
                sx={{ color: 'white' }}
              >
                Next
                <KeyboardArrowRight />
              </Button>
            }
            backButton={
              <Button
                size="small"
                onClick={handleBack}
                disabled={activeStep === 0}
                sx={{ color: 'white' }}
              >
                <KeyboardArrowLeft />
                Back
              </Button>
            }
          />
        </Box>
      </Dialog>
    </>
  );
}

export default PropertyGallery;
